import { EventParserBase } from './event-parse-base';

import { Injectable } from '@angular/core';

import { CookiesProvider } from '../cookies/cookies';

@Injectable({
  providedIn: 'root',
})
export class EventParserProvider extends EventParserBase {

  constructor(public cookies: CookiesProvider) {
    super(cookies);
  }
}
