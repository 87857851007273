import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { LoggerProvider } from '../logger/logger';

import { Content } from '../../models/content';
import { LogData } from '../../models/logData';

const API_URL: string = window['process_env'].API_URL;

const cmsReplacements = {
  'https://{{FUNNEL_URL}}': window['process_env'].FUNNEL_URL,
  'https://{{CART_URL}}': window['process_env'].CART_URL,
  'https://{{AFFILIATE_URL}}': window['process_env'].AFFILIATE_URL,
};

const GET_CONTENT_BY_NAME: string = 'content/byname/';
const GET_CONTENT_BY_SFID: string = 'content/sfid/';

export class TouchcrApiContentBase {
  contentsMap: Map<string, Content> = new Map();

  constructor(
    public http: HttpClient,
    public loggerProvider: LoggerProvider
  ) {}

  getHomeContent(): Observable<Content> {
    return this.getPageContent('home');
  }

  getSubscriptionsContent(): Observable<Content> {
    return this.getPageContent('subscriptions');
  }

  getPageContent(contentName: string): Observable<Content> {
    if (this.contentsMap.get(contentName)) {
      return of(this.contentsMap.get(contentName));
    }
    return this.http.get<Array<Content>>(`${API_URL}${GET_CONTENT_BY_NAME}${contentName}`).pipe(
      map(([contentObj]: Array<Content>) => {
        if (!contentObj) return;

        const contentObjCopy = JSON.parse(JSON.stringify(contentObj));

        Object.keys(cmsReplacements).forEach((key) => {
          contentObjCopy.content = contentObjCopy.content.replace(
            new RegExp(key, 'g'),
            cmsReplacements[key],
          );
        });

        this.contentsMap.set(contentName, contentObjCopy);
        return contentObjCopy;
      }),
      catchError((error) => {
        this.loggerProvider.logToServer({
          level: 'error',
          message: 'Error on getPageContent(): ',
          logObject: { error }
        } as LogData);
        return of(error && error.error && error.error.error);
      }),
    );
  }

  getPageContentBySFId(id: string = ''): Observable<Content> {
    if (!id) {
      return of(null);
    }
    return this.http.get<Content>(`${API_URL}${GET_CONTENT_BY_SFID}${id}`).pipe(
      map((response) => {
        if (!response || !response.content) return null;
        try {
          return JSON.parse(response.content);
        } catch (e) {
          return null;
        }
      }),
      catchError((error) => {
        this.loggerProvider.logToServer({
          level: 'error',
          message: 'Error on getPageContentBySFId(): ',
          logObject: { error }
        } as LogData);
        return of(null);
      }),
    );
  }
}
