import { FunnelSettingsBase } from './funnel-settings-base';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage';

import { LoggingProvider } from '../logging/logging';
import { UrlsProvider } from '../urls/urls';
import { GeneralProvider } from '../general/general';
import { TouchcrApiContentProvider } from '../touchcr-api-content/touchcr-api-content';
import { EventStreamerProvider } from '../event-stream/event-stream';
import { LoggerProvider } from '../logger/logger';
import { pathNames, routes } from '../../app-routing.module';

const FUNNEL_CONFS = window['funnel_confs'] ? window['funnel_confs'].funnels : null;
export const UNIQUE_IDENTIFIER = 'uid';

@Injectable({
  providedIn: 'root',
})
export class FunnelSettingsProvider extends FunnelSettingsBase {

  private mSTATS: string = 'stats';
  private ionContentElementsCreated: number = 0;

  constructor(
    public http: HttpClient,
    public generalProvider: GeneralProvider,
    public storage: Storage,
    public urls: UrlsProvider,
    public contentProvider: TouchcrApiContentProvider,
    public eventStreamer: EventStreamerProvider,
    public loggerProvider: LoggerProvider,
    public loggingProvider: LoggingProvider
  ) {
    super(
      http,
      generalProvider,
      storage,
      urls,
      contentProvider,
      eventStreamer,
      loggerProvider
    );
      // [TOUD-5477]
      this.logMainFunnelByUID();

      setTimeout(async () => {
        let isStorageAvailable = true;
        //Check if storage is unavailable - TCR
        try {
          window.sessionStorage.setItem("StorageTest", "");
          window.sessionStorage.removeItem("StorageTest");
        } catch (e) {
          isStorageAvailable = false;
        }
  
        if (this.urls.getParameterFromUrl({ url: window.location.search, parameter: 'origexperimentalOrig' }) === 'true') {
          console.log('[GH] experimental true: isStorageAvailable: ', isStorageAvailable);
  
          let funnelInfo = this.getCurrentFunnelInfo();
  
          if (!funnelInfo) {
            console.log('[GH] no funnelInfo initially, retrying...');
            funnelInfo = super.getMainFunnelByUID();
            let currentUrl = null;
            const mainFunnel = super.getMainFunnelBySFID(this.urls.getParameterFromUrl({ url: document.location.search, parameter: 'origmainFunnelIdOrig' }));
            let defaultFunnel1 = null;
            try {
              defaultFunnel1 = window['funnel_confs']['funnels'][this.urls.getParameterFromUrl({ url: document.location.search, parameter: 'origmainFunnelIdOrig' })].defaultFunnels[0];
              currentUrl = defaultFunnel1.name;
            } catch (e) {
              console.log('[GH] error ', e);
            }
            const defaultFunnel = defaultFunnel1;
            const splitTestingId = this.urls.getParameterFromUrl({ url: document.location.search, parameter: 'origsplitTestingFunnelIdOrig' });
            funnelInfo = { defaultFunnel, mainFunnel, currentUrl, splitTestingId }
            console.log('[GH] no funnelInfo retry returned ', funnelInfo);
          }
  
  
          if (!funnelInfo) {
            console.log('[GH] no funnelInfo');
            return;
          }
          console.log('[GH] funnelInfo  returned ', funnelInfo);
          const { defaultFunnel, mainFunnel, currentUrl, splitTestingId } = funnelInfo;
          if (!defaultFunnel || !mainFunnel || !currentUrl) {
            if (!defaultFunnel) console.log("[GH] returning from missing defaultFunnel ", defaultFunnel);
            if (!mainFunnel) console.log("[GH] returning from missing mainFunnel ", mainFunnel);
            if (!currentUrl) console.log("[GH] returning from missing currentUrl ", currentUrl);
            return;
          }
  
  
          let spid: any = splitTestingId;
  
          console.log('[GH] splitTestingId type:' + typeof splitTestingId, splitTestingId)
          if (typeof splitTestingId == 'undefined') {
            spid = this.urls.getParameterFromUrl({ url: document.location.search, parameter: 'origsplitTestingFunnelIdOrig' });
          }
  
          let sesi = setInterval(() => {
            console.log('[GH] checking for sess...');
            if (typeof window['tcrsessioncreated'] == 'boolean' && window['tcrsessioncreated']) {
              console.log('[GH] found sess...');
              window.clearInterval(sesi);
              const dsid: any = this.urls.getParameterFromUrl({ url: document.location.search, parameter: 'origdsidOrig' }) || null;
              setTimeout(async () => {
                let funnelInfo = this.getCurrentFunnelInfo();
                if (this.urls.getParameterFromUrl({ url: document.location.search, parameter: 'origExternalOrig' }) == 'true') {
                  funnelInfo = super.getMainFunnelByUID();
                  let currentUrl = null;
                  const mainFunnel = super.getMainFunnelBySFID(this.urls.getParameterFromUrl({ url: document.location.search, parameter: 'origmainFunnelIdOrig' }));
                  let defaultFunnel1 = null;
                  try {
                    defaultFunnel1 = window['funnel_confs']['funnels'][this.urls.getParameterFromUrl({ url: document.location.search, parameter: 'origmainFunnelIdOrig' })].defaultFunnels[0];
                    currentUrl = defaultFunnel1.name;
                  } catch (e) {
                    console.log('[GH] 2 error ', e);
                  }
                  const defaultFunnel = defaultFunnel1;
                  const splitTestingId = this.urls.getParameterFromUrl({ url: document.location.search, parameter: 'origsplitTestingFunnelIdOrig' });
                  funnelInfo = { defaultFunnel, mainFunnel, currentUrl, splitTestingId }
                }
                if (!funnelInfo) {
                  console.log('[GH] no funnelInfo');
                  return;
                }
                let { defaultFunnel, mainFunnel, currentUrl, splitTestingId } = funnelInfo;
                if (!defaultFunnel || !mainFunnel || !currentUrl) return;
                console.log('[GH] experimental funnel events', mainFunnel);
                window['sendorigdsidOrig'] = true;
                try {
                  defaultFunnel = window['funnel_confs']['funnels'][this.urls.getParameterFromUrl({ url: document.location.search, parameter: 'origmainFunnelIdOrig' })].defaultFunnels[0];
                } catch (e) {
                  console.log('[GH] error ', e);
                }
  
                if (this.urls.getParameterFromUrl({ url: document.location.search, parameter: 'origExternalOrig' }) == 'true') {
                  try {
                    mainFunnel = window['funnel_confs']['funnels'][this.urls.getParameterFromUrl({ url: document.location.search, parameter: 'origExternalIDOrig' })];
                    defaultFunnel = mainFunnel;
                  } catch (e) {
                    console.log('[GH] error ', e);
                  }
  
                  if (typeof window['TCRExperimentalPageViewSent'] == 'undefined') {
                    window['TCRExperimentalPageViewSent'] = true;
                    await this.sendCustomStats('Page View', { experimental: true, super: false });
                  }
  
                  super.setSessionIDGenerated(true);
  
                  await super.sendStatsToAPIDest({
                    funnelSTFId: mainFunnel.sfid,
                    destinationId: dsid,
                    splitTestingId: spid,
                    eventType: 'Destination',
                  });
                  super.setSessionIDGenerated(true);
  
                  await super.sendStatsToAPIDest({
                    funnelSTFId: mainFunnel.sfid,
                    destinationId: dsid,
                    splitTestingId: spid,
                    eventType: 'New Session',
                  });
                } else {
  
                  this.sendStatsToAPI({
                    defaultFunnel: defaultFunnel,
                    mainFunnelID: this.urls.getParameterFromUrl({ url: document.location.search, parameter: 'origmainFunnelIdOrig' }),
                    url: defaultFunnel.name,
                    splitTestingFunnelId: this.urls.getParameterFromUrl({ url: document.location.search, parameter: "origsplitTestingFunnelIdOrig" }),
                    funnelSTPId: this.urls.getParameterFromUrl({ url: document.location.search, parameter: 'origsplitTestingFunnelIdOrig' }) || null,
                    customPayLoad1: JSON.stringify({}) || null,
                    customPayLoad2: JSON.stringify({}) || null,
                    eventType: 'Page View'
                  });
  
                  window['sendorigdsidOrig'] = true;
                  console.log('[GH] Experimental preload page view event call1', document.location.href);
                  // this  looks like the duplicate causing the issue in AT-878
                  //await super.sendCustomStats('Page View', { experimental: true, super: false, sendorig: true });
                  this.setSessionIDGenerated(false);
  
                  super.sendStatsToAPIDest({
                    funnelSTFId: mainFunnel.sfid,
                    destinationId: dsid,
                    splitTestingId: spid,
                    eventType: 'Destination',
                  });
                  this.setSessionIDGenerated(false);
  
                  super.sendStatsToAPIDest({
                    funnelSTFId: mainFunnel.sfid,
                    destinationId: dsid,
                    splitTestingId: spid,
                    eventType: 'New Session',
                  });
  
                }
  
              }, 1000);
  
  
            }
          }, 100);
  
        } else {
          console.log('[GH] not experimental ' + this.urls.getParameterFromUrl({ url: window.location.search, parameter: 'step' }) + "::" + this.urls.getParameterFromUrl({ url: window.location.search, parameter: 'origexperimentalOrig' }));
        }
      }, 30);

      try {
        setInterval(() => {
          if( document.querySelectorAll('ion-content').length > this.ionContentElementsCreated ) {
            this.styleScrollbars(this.ionContentElementsCreated++);
          }
        },30);
      } catch (e) {
        console.log('[GH] error styling scrollbar', e);
      }
  }


  styleScrollbars(ele) {
    let elmt = document.querySelectorAll('ion-content')[ele];
    if(!elmt) return;
    const stylesheet = `
      ::-webkit-scrollbar {
        width:8px;
        max-width: 12px;
      }
      
      ::-webkit-scrollbar-track {
        -webkit-border-radius: 10px;
        border-radius: 10px;
      }
      ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: #2c507c;  
      }
      
      ::-webkit-scrollbar-thumb:window-inactive {
        background: #2c507c;
      }
    `;

    const styleElmt = elmt.shadowRoot.querySelector('style');

    if (styleElmt) {
      styleElmt.append(stylesheet);
    } else {
      const barStyle = document.createElement('style');
      barStyle.append(stylesheet);
      elmt.shadowRoot.appendChild(barStyle);
    }
  }

  async saveUserStats(stats: any = {}) {
    if (!stats) return;
    stats.deviceInfo = await this.generalProvider.getDeviceInfo();
    await this.storage.set(this.mSTATS, stats);
  }

  getUserStats() {
    return this.storage.get(this.mSTATS);
  }


  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  async sendStatsToAPIDest(data: any) {
    try { // [TOUC-4669]
      if (!window.sessionStorage.getItem("fecnt")) {
        window.sessionStorage.setItem("fecnt", "0");
      }
      window.sessionStorage.setItem("fecnt", (parseInt(window.sessionStorage.getItem("fecnt")) + 1).toString());
      if((parseInt(window.sessionStorage.getItem("fecnt"))>300)) {
        console.log("[GH] detected 300 events in session already " );
        return;
      }
    } catch (e) {
      if (!window['fecnt']) {
        window['fecnt'] = 0;
      }
      window['fecnt'] = (window['fecnt'] || 0) + 1;
      if((parseInt(window['fecnt'])>300)) {
        console.log("[GH] detected 300 events on window already " );
        return;
      }
    }
    console.log('sendStatsToAPIDest(): ', data);
    super.sendStatsToAPIDest(data);
    if(data && data.eventType && data.eventType.indexOf('Explorium') === -1)
      this.ghtracking(data.eventType);
   }

  async sendStatsToAPI(data: any) {
    try { // [TOUC-4669]
      if (!window.sessionStorage.getItem("fecnt")) {
        window.sessionStorage.setItem("fecnt", "0");
      }
      window.sessionStorage.setItem("fecnt", (parseInt(window.sessionStorage.getItem("fecnt")) + 1).toString());
      if((parseInt(window.sessionStorage.getItem("fecnt"))>300)) {
        console.log("[GH] detected 300 events in session already " );
        return;
      }
    } catch (e) {
      if (!window['fecnt']) {
        window['fecnt'] = 0;
      }
      window['fecnt'] = (window['fecnt'] || 0) + 1;
      if((parseInt(window['fecnt'])>300)) {
        console.log("[GH] detected 300 events on window already " );
        return;
      }
    }
    console.log('sendStatsToAPI(): ', data);
    super.sendStatsToAPI(data);
    if(data && data.eventType && data.eventType.indexOf('Explorium') === -1)
      this.ghtracking(data.eventType);
  }
  getAffiliateInfo() {
    return window.sessionStorage.getItem("affiliateInfo") ;
  }
  async ghtracking (eventType) {
    setTimeout(() => {
      try {
        console.log('[GH] tracking event type: ' + eventType);
        let explorium1Payload = {};  
        explorium1Payload['alternaiGuestId'] = this.getCookie('alternaiGuestId');
        explorium1Payload['alternaiVisitorId'] = this.getCookie('alternaiVisitorId');
        if (!explorium1Payload['ip'] && window['gh'] && window['gh']['ip']) explorium1Payload['ip'] = window['gh']['ip'];
        if(navigator.userAgent.length > 128)
          explorium1Payload['ua'] = navigator.userAgent.substring(0,128);
        else
          explorium1Payload['ua'] = navigator.userAgent;
        console.log("[GH] sending signal explorium new session", explorium1Payload);
        if(eventType === 'Page View' || eventType === 'New Session') this.sendCustomStats("Explorium-NewSession", explorium1Payload);
      } catch (e) {
        console.log("Error with new logging on destination : " + e);
      } 
    },300);
  }

  getComponent(url: any = '') {
    if (!url) return;
    const component: any = routes.find((e) => e.path === url);
    if (!component) {
      // TOUC-480 log when deep link config derives no component and the user is about to redirect to the default ROUTE
      // remove the uuid from the query string to prevent the default landing page from being broken
      this.loggingProvider.logEvent('component not found');

      let newQuery = window.location.search;
      if (this.urls.getParameterFromUrl({ url: newQuery, parameter: UNIQUE_IDENTIFIER })) {
        newQuery =  this.urls.removeParamFromQuery(newQuery, UNIQUE_IDENTIFIER);
      }
      this.urls.setQueryParams(newQuery);

      return;
    }
    const pathName: any = pathNames.find((e) => e.path === url);
    if (pathName) {
      component['name'] = pathName.name;
    } else {
      component['name'] = component.path;
    }
    return component;
  }

  isFunnelSetupCorrectly(genericUrls: string) {
    try {
      const strArr = genericUrls.split(',');
      let genericUrl = '';
      for (let i = 0; i < strArr.length; i++) {
        genericUrl = strArr[i];
        const mainFunnel = this.getMainFunnelByGenericUrl(genericUrl);
        if (!mainFunnel) {
          console.log('funnel not setup correctly: ' + genericUrl, FUNNEL_CONFS);
          this.loggingProvider.logEvent('funnel not setup correctly: ');
          return false;
        }
      }
    } catch (e) {}
    return true;
  }

  logMainFunnelByUID() {
    try {
      if (this.isUIDExist()) {
        const uniqueIdentifier = this.urls.getParameterFromUrl({
          url: window.location.href,
          parameter: UNIQUE_IDENTIFIER,
        });
        const dsid = this.urls.getParameterFromUrl({
          url: window.location.href,
          parameter: "dsid",
        });
        const cstp = this.urls.getParameterFromUrl({
          url: window.location.href,
          parameter: "step",
        });
        const sid = this.urls.getParameterFromUrl({
          url: window.location.href,
          parameter: "sessionid",
        });
        if (uniqueIdentifier) {
          const mainFunnel = Object.keys(FUNNEL_CONFS)
            .map((key) => {
              return FUNNEL_CONFS[key];
            })
            .find((e) => e.uniqueIdentifier === uniqueIdentifier);
            console.log("FUNNEL_CONFS FOR UID " + uniqueIdentifier,mainFunnel);
            let stp=1;
            if(mainFunnel && mainFunnel.defaultFunnels) {
              mainFunnel.defaultFunnels.forEach((item) => {
                let itm = item.step;
                item.variants.forEach((variant) => {
                  let srch = document.location.search;
                  if (cstp)
                    srch = srch.replace("step=" + cstp, ("step=" + itm)); // change the step 
                  else srch += "&step=" + itm;
                  if (dsid) srch = srch.replace(dsid, variant.sfid);
                  else srch += "&dsid=" + variant.sfid;
                  srch = srch.replace("&sessionid=" + sid, ""); // remove the session id
                  console.log(window.location.origin + '/' + variant.url + srch + "&pseudoState=true");
                  stp++;
                });
              });
            }
        }
      }
    } catch (e) {
      console.log("Error logging UID data "+e);
    }
  }
}