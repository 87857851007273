import { CookiesBase } from './cookies-base';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CookiesProvider extends CookiesBase {
  constructor() {
    super();
  }
}
