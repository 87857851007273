import { GeneralBase } from './general-base';

import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Platform } from '@ionic/angular';

import { UrlsProvider } from '../urls/urls';
import { HandlerProvider } from '../handler/handler';

@Injectable({
  providedIn: 'root',
})
export class GeneralProvider extends GeneralBase {
  constructor(
    public mLocation: Location,
    public platform: Platform,
    public urls: UrlsProvider,
    public handler: HandlerProvider
) {
    super(
      mLocation,
      platform,
      urls,
      handler
    );
  }

  setPageTitle(title: string) {
    const titleId = document.getElementById('title');
    if (title && titleId) {
      titleId.innerHTML = title;
    }
  }
}
