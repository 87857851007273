import { ToastController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';

import { LogData } from '../../models/logData';

import { SMTH_WENT_WRONG } from '../../constants/constants';

export class LoggerBase {

  protected static readonly API_URL = window['process_env'].API_URL;
  protected static readonly LOGGER_ENDPOINT = '';

  protected isLoggerEnabled: boolean = window['process_env'].ENABLE_LOGGER;

  constructor(
    public http: HttpClient,
    public toastCtrl: ToastController
  ) {}

  /**
   * @apiDescription This method used to programmatically override
   *                 isLoggerEnabled flag and force logging
   * @api {set}
   * @apiName overrideLoggerEnabled
   * @apiGroup LoggerProvider
   * @apiParam {boolean} value Override isLoggerEnabled value
   */
  public overrideLoggerEnabled(value: boolean): void {
    this.isLoggerEnabled = value;
  }

  /**
   * @apiDescription Logs data to remote server and to console
   * @api {set}
   * @apiName logToServer
   * @apiGroup LoggerProvider
   * @apiParam {LogData} data Object containing log data
   */
  public logToServer(data: LogData): Promise<any> {
    if (data) {
      // Log to console
      this.logToConsole(data);
      // Send request to remote server
      return this.http.post(`${LoggerBase.API_URL}${LoggerBase.LOGGER_ENDPOINT}`, data).toPromise()
        .catch((error) => {
          // Log to console on POST error
          this.logToConsole({
            level: 'error',
            message: SMTH_WENT_WRONG,
            logObject: { error }
          } as LogData);
        });
    } else {
      return Promise.resolve();
    }
  }

  /**
   * @apiDescription Logs data to console
   * @api {set}
   * @apiName outputToConsole
   * @apiGroup LoggerProvider
   * @apiParam {LogData} data Object containing log data
   * @apiParamExample {Object} LogData type Object
   * {
   *   level: 'log' | 'error',
   *   message: string,
   *   logObject?: { [key: string]: any }
   * }
   */
  public logToConsole(data: LogData): void {
    if (this.isLoggerEnabled && data) {
      const { level, message, logObject } = data;

      let logger;
      switch (level) {
        case 'error':
          logger = console.error;
          break;
        default:
          logger = console.log;
          break;
      }

      if (logObject) {
        const logEntries = Object.keys(logObject);
        if (logEntries.length) {
          console.groupCollapsed(`[${(new Date()).toISOString()}] ${message}`);
          for (const logEntry of logEntries) {
            logger(logEntry, logObject[logEntry]);
          }
          console.groupEnd();
          return;
        }
      }

      logger(`[${(new Date()).toISOString()}] ${message}`);
    }
  }
}
