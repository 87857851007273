import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { UrlsProvider } from '../urls/urls';
import { TouchcrApiAffiliateProvider } from '../touchcr-api-affiliate/touchcr-api-affiliate';
import { v4 as uuid } from 'uuid';
const { API_URL, TOKEN, BRAND, NODE_ENV } = window['process_env'];

export class HttpInterceptorBase implements HttpInterceptor {
  constructor(
    public urls: UrlsProvider,
    public tcrApiAffiliate: TouchcrApiAffiliateProvider
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to add the new header
    if (BRAND && req.url.includes(API_URL)) {
      let url = req.url;
      const body = req.body;
      // Use proxy only if production);
      if (body) {
        let affParameters = JSON.parse(window.sessionStorage.getItem('affiliateInfo'));
        if (!affParameters) {
          affParameters = this.urls.getAffParamsFromUrl(this.urls.getQueryParams());
          this.tcrApiAffiliate.saveAffParamsToSessionStorage(affParameters);
        }
        body.affParameters = affParameters;
      }
      if (NODE_ENV === 'production') {
        url = '/proxy/' + req.url.split(API_URL)[1];
      }
      let mHeaders = req.headers.set('X-Brand', BRAND);
      // [TOUC-5682]
      if (typeof window['getCookie'] === 'function') {
        mHeaders = mHeaders.append('XSRF-TOKEN', window['getCookie']('XSRF-TOKEN'));
      }
      
      
      // Add a UUID for all outgoing requests for API Tracking purposes
      let uid = uuid();
      mHeaders = mHeaders.append('X-Qinship-Request-Id', uid);
      if (TOKEN) {
        mHeaders = mHeaders.append('Authorization', TOKEN);
      }
      const clonedRequest = req.clone({
        url,
        headers: mHeaders,
        body,
        withCredentials: true,
      });
      return next.handle(clonedRequest);
    }
    return next.handle(req);
  }
}
