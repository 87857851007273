import { Platform } from '@ionic/angular';
import { Location, PopStateEvent } from '@angular/common';

import { SubscriptionLike } from 'rxjs';

import { UrlsProvider } from '../urls/urls';
import { HandlerProvider } from '../handler/handler';

import { Country } from '../../models/country';
import { ErrorOptions } from '../../models/errorOptions';

declare const InstallTrigger: any;

/**
 * GeneralProvider class
 * provides general methods for pages and components
 */
export class GeneralBase {
  public locationEventsSubscription: SubscriptionLike;

  private mWindow: any = window;

  constructor(
    public mLocation: Location,
    public platform: Platform,
    public urls: UrlsProvider,
    public handler: HandlerProvider
  ) {}

  /** Return list of next 12 years */
  getExpiryYearList(currentYear) {
    /** Init result param */
    const result = [];
    /** Filling list of expiry years */
    for (let i = currentYear; i <= currentYear + 12; i++) {
      result.push(i);
    }
    /** Return list of current and next 12 years */
    return result;
  }

  isMobile() {
    return /Mobi/.test(navigator.userAgent);
  }

  getBrowserName() {
    // Firefox 1.0+
    if (typeof InstallTrigger !== 'undefined') {
      return 'Firefox';
    }

    if (this.mWindow.chrome) {
      return 'Chrome';
    }

    // Opera 8.0+
    if (
      (this.mWindow.opr && this.mWindow.opr.addons) ||
      this.mWindow.opera ||
      navigator.userAgent.indexOf(' OPR/') >= 0
    ) {
      return 'Opera';
    }

    // Safari 3.0+ "[object HTMLElementConstructor]"
    if (
      /constructor/i.test(this.mWindow.HTMLElement) ||
      ((p) => {
        return p.toString() === '[object SafariRemoteNotification]';
      })(!this.mWindow['safari'])
    ) {
      return 'Safari';
    }

    // Internet Explorer 6-11
    if (/* @cc_on!@*/ false || document.DOCUMENT_NODE) {
      return 'Internet Explorer';
    }

    // Edge 20+
    if (this.mWindow.styleMedia) {
      return 'Microsoft Edge';
    }
  }

  androidVersion() {
    if (/Android/.test(navigator.appVersion)) {
      return navigator.appVersion.match(/Android (\d+).(\d+)/);
    }
  }

  IOSVersion() {
    if (/iP(hone|od|ad)/.test(navigator.appVersion)) {
      const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
      return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt('' + (v[3] || 0), 10)];
    }
  }

  getOSName() {
    const userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }

    return os;
  }

  getDeviceInfo() {
    return {
      os: this.getOSName(),
      browser: this.getBrowserName(),
      language: navigator.language,
      device: this.isMobile() ? 'Mobile' : 'Desktop',
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      screen_resolution: screen.width + ' x ' + screen.height,
    };
  }


  seedsessionId = (function xoshiro128p() {
    let a = Date.now(),
      b = Date.now(),
      c = Date.now(),
      d = Date.now()
    return function () {
      let t = b << 9,
        r = a + d
      c = c ^ a
      d = d ^ b
      b = b ^ c
      a = a ^ d
      c = c ^ t
      d = (d << 11) | (d >>> 21)
      return (r >>> 0) / 4294967296
    }
  })()
  // Create new session ID
  generateRandomString() {
    var array = new Uint32Array(20);
    window.crypto.getRandomValues(array);
    let ret = 0;
    for (var i = 0; i < array.length; i++) {
      console.log(array[i]);
      ret += parseInt(array[i].toString());
    }
    return ret / 5;
  }
  getNewSession() {
    const now = new Date();
    let sessionId = (+now * this.seedsessionId() * this.seedsessionId()).toString();
    if(typeof window.crypto === 'object') {
      sessionId = this.generateRandomString().toString().substring(0,20);
    }
    sessionId += now.getFullYear().toString();
    sessionId += (now.getMonth() < 9 ? '0' : '') + now.getMonth().toString();
    sessionId += (now.getDate() < 10 ? '0' : '') + now.getDate();
    sessionId = (+sessionId).toFixed(0);
    return sessionId.toString().substring(0,14);
  }

  // Generate ID for HTML element
  generateUniqueID(prefix: string) {
    const postfix = this.urls.getParameterFromUrl({ url: this.urls.getQueryParams(), parameter: 'step' }) || (+(new Date())).toString();
    return `${prefix}-${postfix}`;
  }

  // Method for disabling browser back button on upsells and order success pages
  disableBackButton() {
    const callback = () => {
      history.forward();
    };

    this.setLocationEventHandler(callback);

    window.history.pushState(null, null, window.location.href);
    history.back();
  }

  // Enables back button functionality
  enableBackButton() {
    const callback = (event: PopStateEvent) => {
      this.urls.setQueryParams(this.urls.getQueryParamsFromUrl(event.url));
      this.urls.replaceInitialQueryParams();
    };

    this.setLocationEventHandler(callback);
  }

  // Event handler for location PopState events
  private setLocationEventHandler(callback) {
    if (this.locationEventsSubscription) {
      this.locationEventsSubscription.unsubscribe();
    }

    this.locationEventsSubscription = this.mLocation.subscribe(
      (event: PopStateEvent) => {
        callback(event);
      },
      (error: any) => {
        this.handler.handleError({ error, methodName: 'backButtonHandler' } as ErrorOptions);
      }
    );
  }

  // Creates countries object
  getCountriesObject(countries: Array<any>): any {
    const countriesObject = {} as any;

    if (countries) {
      // Creates a full copy of income array
      const countriesList = JSON.parse(JSON.stringify(countries));

      countriesList.forEach(country => {
        countriesObject[country.label] = country;
      });
    }

    return countriesObject;
  }

  // Returns country by it's code
  getCountryByCode(countriesList: Array<any>, countryCode: string): Country {
    let countryByCode = null;

    if (countriesList && countryCode) {
      for (const country of countriesList) {
        if (country.value === countryCode) {
          countryByCode = country;
          break;
        }
      }
    }

    return countryByCode;
  }

  // Returns states list for specified country
  getStatesList(country: Country): Array<any> {
    const states = [];

    if (country) {
      const countryStates = country.states;

      for (const stateCode of Object.keys(countryStates)) {
        const state = {};
        state['value'] = stateCode;
        state['label'] = countryStates[stateCode];
        states.push(state);
      }

      states.reverse();
    }

    return states;
  }

  // Method which unsubscribes from subscription
  unsubscribe(subscription: SubscriptionLike) {
    if (subscription) {
      subscription.unsubscribe();
    }
  }
}