import { LoadingController } from '@ionic/angular';
import { LoadingOptions } from '@ionic/core';

export class SpinnerBase {
  /**
   * @apiDescription Singleton implementation of loading controller.
   *                 Uses Ionic LoadingController
   */

  private spinner: {
    isEnabled: boolean,
    loadingController: any
  } = {
    isEnabled: false,
    loadingController: null
  };

  constructor(public loadingCtrl: LoadingController) {}

  /**
   * @apiParam {LoadingOptions} options See LoadingOptions from @ionic/core.
   *                                    Allows to pass options on spinner
   *                                    creation.
   * @apiExample To show a spinner import SpinnerProvider and make
   *             a call to enable() method:
   *   this.spinner.enable({ message: 'Loading...' });
   * @apiExample To show a new spinner simply call to enable() method
   *             (no need to disable currently active instance):
   *   this.spinner.enable({
   *     message: 'New loading message...',
   *     cssClass: 'green-background-color'
   *   });
   */
  async enable(options: LoadingOptions) {
    if (options) {
      if (this.spinner.isEnabled) {
        for (const prop of Object.keys(options)) {
          this.spinner.loadingController[prop] = options[prop];
        }
      } else {
        this.spinner.loadingController = await this.loadingCtrl.create(options);
        await this.spinner.loadingController.present();
        this.spinner.isEnabled = true;
      }
    }
  }

  /**
   * @apiExample Disables currently active spinner instance
   *   this.spinner.disable();
   */
  async disable() {
    if (this.spinner.isEnabled) {
      await this.spinner.loadingController.dismiss();
      this.spinner.isEnabled = false;
    }
  }
}
