import { HttpInterceptorBase } from './http-interceptor-base';

import { Injectable } from '@angular/core';

import { UrlsProvider } from '../urls/urls';
import { TouchcrApiAffiliateProvider } from '../touchcr-api-affiliate/touchcr-api-affiliate';

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorProvider extends HttpInterceptorBase {

  constructor(
    public urls: UrlsProvider,
    public tcrApiAffiliate: TouchcrApiAffiliateProvider
  ) {
    super(
      urls,
      tcrApiAffiliate
    );
  }
}
