import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PathName } from './models/pathName';

export const routes: Routes = [
  { path: 'cms/:page', loadChildren: () => import('./pages/base-pages/cms/cms.module').then((mod) => mod.CmsPageModule) },
  { path: 'home', loadChildren: () => import('./pages/home/home.module').then((mod) => mod.HomePageModule) },
  { path: 'article-common-gh', loadChildren: () => import('./pages/_brand-pages-base/base-templates/article/article-common-gh.module').then((mod) => mod.ArticleCommonPageModule) },
  { path: 'vsl', loadChildren: () => import('./pages/_brand-pages-base/base-templates/video/video-common-gh.module').then((mod) => mod.VideoVslPageModule) },
  { path: 'survey', loadChildren: () => import('./pages/_brand-pages-base/base-templates/survey/survey-common-gh.module').then((mod) => mod.SurveyCommonGhPageModule) },
  {
    path: 'offer-selector', loadChildren: () => import('./pages/_brand-pages-base/base-templates/offer-selector/offer-selector-common-gh.module').then((mod) => mod.OfferSelectorGhPageModule ) 
  },
  {
    path: 'os',
    loadChildren: () => import('./pages/offer-selector-pages/secureOrder201120a/secureOrder201120a.module').then(mod => mod.SecureOrder201120aPageModule)
  },
  { path: 'two-step-order-form-common', loadChildren: () => import( './pages/_brand-pages-base/base-templates/checkout/two-step-order-form-common.module' ).then((mod) => mod.TwoStepOrderFormCommonPageModule) },
  { path: 'SecureCart', loadChildren: () => import('./pages/_brand-pages-base/product-common-pages/SecureCart/SecureCart.module').then( (mod) => mod.SecureCartPageModule ) },
  { path: 'BHMD_SecureCart', loadChildren: () => import('./pages/_brand-pages-base/product-common-pages/SecureCart/SecureCart.module').then( (mod) => mod.SecureCartPageModule ) },
  { path: 'BHMD_SecureCart_1999', loadChildren: () => import('./pages/_brand-pages-base/product-common-pages/SecureCart/SecureCart.module').then( (mod) => mod.SecureCartPageModule ) },
  { path: 'BHMD_SecureCart_220626', loadChildren: () => import('./pages/_brand-pages-base/product-common-pages/SecureCart/SecureCart.module').then( (mod) => mod.SecureCartPageModule ) },
  { path: 'BHMD_SecureCart_220726', loadChildren: () => import('./pages/_brand-pages-base/product-common-pages/SecureCart/SecureCart.module').then( (mod) => mod.SecureCartPageModule ) },
  { path: 'BHMD_SecureCart_220926', loadChildren: () => import('./pages/_brand-pages-base/product-common-pages/SecureCart/SecureCart.module').then( (mod) => mod.SecureCartPageModule ) },
  { path: 'CTUS_12-Unit-DermalRepairComplex_AP', loadChildren: ()=> import('./pages/upsells-downsells/applepay/upsells/dermalrepaircomplex-us-12unit-191009a.module').then(mod => mod.DermalrepaircomplexUs12unit191009aPageModule) },
  { path: 'CTUS_3-Unit-DermalRepairComplex_AP', loadChildren: ()=> import('./pages/upsells-downsells/applepay/upsells/dermalrepaircomplex-us-3unit-191009a.module').then(mod => mod.DermalrepaircomplexUs3unit191009aPageModule) },
  { path: 'CTUS_6-Unit-DermalRepairComplex_AP', loadChildren: ()=> import('./pages/upsells-downsells/applepay/upsells/dermalrepaircomplex-us-6unit-191009a.module').then(mod => mod.DermalrepaircomplexUs6unit191009aPageModule) },
  
  //DEV-7165
  { path: 'CTUS_6-Unit-DermalRepairComplex_200410_AP', loadChildren: ()=> import('./pages/upsells-downsells/applepay/upsells/dermalrepaircomplex-us-6unit-200410a.module').then(mod => mod.DermalrepaircomplexUs6unit200410aPageModule) },
  { path: 'DS_3-Unit-DermalRepairComplex_200410_AP', loadChildren: ()=> import('./pages/upsells-downsells/applepay/downsells/dermalrepaircomplex-ds-3unit-200410a.module').then(mod => mod.DermalrepaircomplexDs3unit200410aPageModule) },
  { path: 'US_6-Unit-LiftFirm-DRC-3-6_200410_AP', loadChildren: ()=> import('./pages/upsells-downsells/applepay/upsells/liftfirm-us-6unit-200410a.module').then(mod => mod.LiftfirmUs6unit200410aPageModule) },
  { path: 'DS_3-Unit-LiftFirm_200410_AP', loadChildren: ()=> import('./pages/upsells-downsells/applepay/downsells/liftfirm-ds-3unit-200410a.module').then(mod => mod.LiftfirmDs3unit200410aPageModule) },
  { path: 'US_6-Unit-CrepeCorrecting-DRC-3-6_200410_AP', loadChildren: ()=> import('./pages/upsells-downsells/applepay/upsells/crepecorrecting-us-6unit-200410b.module').then(mod => mod.CrepecorrectingUs6unit200410bPageModule) },
  { path: 'DS_3-Unit-CrepeCorrecting_200410_AP', loadChildren: ()=> import('./pages/upsells-downsells/applepay/downsells/crepecorrecting-ds-3unit-200410a.module').then(mod => mod.CrepecorrectingDs3unit200410aPageModule) },
  { path: 'CTUS_3-Unit-DermalRepairComplex_200410_AP', loadChildren: ()=> import('./pages/upsells-downsells/applepay/upsells/dermalrepaircomplex-us-3unit-200410a.module').then(mod => mod.DermalrepaircomplexUs3unit200410aPageModule) },
  { path: 'DS_1-Unit-DermalRepairComplex_200410_AP', loadChildren: ()=> import('./pages/upsells-downsells/applepay/downsells/dermalrepaircomplex-ds-1unit-200410a.module').then(mod => mod.DermalrepaircomplexDs1unit200410aPageModule) },
  { path: 'US_3-Unit-LiftFirm-DRC-1_200410_AP', loadChildren: ()=> import('./pages/upsells-downsells/applepay/upsells/liftfirm-us-3unit-200410a.module').then(mod => mod.LiftfirmUs3unit200410aPageModule) },
  { path: 'DS_1-Unit-LiftFirm_200410_AP', loadChildren: ()=> import('./pages/upsells-downsells/applepay/downsells/liftfirm-ds-1unit-200410a.module').then(mod => mod.LiftfirmDs1unit200410aPageModule) },
  { path: 'US_3-Unit-CrepeCorrecting-DRC-1_200410_AP', loadChildren: ()=> import('./pages/upsells-downsells/applepay/upsells/crepecorrecting-us-3unit-200410a.module').then(mod => mod.CrepecorrectingUs3unit200410aPageModule) },
  { path: 'DS_1-Unit-CrepeCorrecting_200410_AP', loadChildren: ()=> import('./pages/upsells-downsells/applepay/downsells/crepecorrecting-ds-1unit-200410a.module').then(mod => mod.CrepecorrectingDs1unit200410aPageModule) },
  { path: 'CTUS_12-Unit-DermalRepairComplex_200410_AP', loadChildren: ()=> import('./pages/upsells-downsells/applepay/upsells/dermalrepaircomplex-us-12unit-200410a.module').then(mod => mod.DermalrepaircomplexUs12unit200410aPageModule) },
  { path: 'DS_6-Unit-DermalRepairComplex_200410_AP', loadChildren: ()=> import('./pages/upsells-downsells/applepay/downsells/dermalrepaircomplex-ds-6unit-200410a.module').then(mod => mod.DermalrepaircomplexDs6unit200410aPageModule) },
  
  

  { path: 'DS_1-Unit-DermalRepairComplex_AP', loadChildren: ()=> import('./pages/upsells-downsells/applepay/downsells/dermalrepaircomplex-ds-1unit-191220a.module').then(mod => mod.DermalrepaircomplexDs1unit191220aPageModule) },
  { path: 'DS_6-Unit-DermalRepairComplex_AP', loadChildren: ()=> import('./pages/upsells-downsells/applepay/downsells/dermalrepaircomplex-ds-6unit-191220a.module').then(mod => mod.DermalrepaircomplexDs6unit191220aPageModule) },
  { path: 'DS_3-Unit-DermalRepairComplex_AP', loadChildren: ()=> import('./pages/upsells-downsells/applepay/downsells/dermalrepaircomplex-ds-3unit-191220a.module').then(mod => mod.DermalrepaircomplexDs3unit191220aPageModule) },

  { path: 'US_3-Unit-CrepeCorrecting-DRC-1_AP', loadChildren: ()=> import('./pages/upsells-downsells/applepay/upsells/crepecorrecting-us-3unit-191009a.module').then(mod => mod.CrepecorrectingUs3unit191009aPageModule) },
  { path: 'US_6-Unit-CrepeCorrecting-DRC-3-6_AP', loadChildren: ()=> import('./pages/upsells-downsells/applepay/upsells/crepecorrecting-us-6unit-191009b.module').then(mod => mod.CrepecorrectingUs6unit191009bPageModule) },

  { path: 'DS_1-Unit-CrepeCorrecting_AP', loadChildren: ()=> import('./pages/upsells-downsells/applepay/downsells/crepecorrecting-ds-1unit-191009a.module').then(mod => mod.CrepecorrectingDs1unit191009aPageModule) },
  { path: 'DS_3-Unit-CrepeCorrecting_AP', loadChildren: ()=> import('./pages/upsells-downsells/applepay/downsells/crepecorrecting-ds-3unit-191009a.module').then(mod => mod.CrepecorrectingDs3unit191009aPageModule) },

  { path: 'DS_1-Unit-LiftFirm_AP', loadChildren: ()=> import('./pages/upsells-downsells/applepay/downsells/liftfirm-ds-1unit-191226a.module').then(mod => mod.LiftfirmDs1unit191226aPageModule) },
  { path: 'DS_3-Unit-LiftFirm_AP', loadChildren: ()=> import('./pages/upsells-downsells/applepay/downsells/liftfirm-ds-3unit-191226a.module').then(mod => mod.LiftfirmDs3unit191226aPageModule) },

  { path: 'US_3-Unit-LiftFirm-DRC-1_AP', loadChildren: ()=> import('./pages/upsells-downsells/applepay/upsells/liftfirm-us-3unit-191009a.module').then(mod => mod.LiftfirmUs3unit191009aPageModule) },
  { path: 'US_6-Unit-LiftFirm-DRC-3-6_AP', loadChildren: ()=> import('./pages/upsells-downsells/applepay/upsells/liftfirm-us-6unit-191009a.module').then(mod => mod.LiftfirmUs6unit191009aPageModule) },

  { path: 'BHMD_Receipt_AP', loadChildren: () => import( './pages/_brand-pages-base/product-common-pages/order-success/order-success-common.module' ).then((mod) => mod.OrderSuccessCommonPageModule) },
  { path: 'safe-order', loadChildren: () => import('./pages/safe-order/safe-order.module').then((mod) => mod.SafeOrderPageModule) },


  { path: 'order-success-common', loadChildren: () => import( './pages/_brand-pages-base/product-common-pages/order-success/order-success-common.module' ).then((mod) => mod.OrderSuccessCommonPageModule) },
  { path: 'Brand-Receipt_AP', loadChildren: () => import( './pages/_brand-pages-base/product-common-pages/order-success/order-success-common.module' ).then((mod) => mod.OrderSuccessCommonPageModule) },
  { path: 'study', loadChildren: () => import('./pages/study/study.module').then((mod) => mod.StudyModule), },
  { path: 'policy', loadChildren: () => import('./pages/policy/policy.module').then((mod) => mod.PolicyModule), },
  { path: 'privacy-policy', loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then((mod) => mod.PrivacyPolicyModule) },
  { path: 'ccpa', loadChildren: () => import('./pages/ccpa/ccpa.module').then((mod) => mod.CcpaModule) },
  { path: 'gdpr', loadChildren: () => import('./pages/gdpr/gdpr.module').then((mod) => mod.GdprModule) },
  { path: 'optout', loadChildren: () => import('./pages/opt-out/opt-out.module').then((mod) => mod.OptOutModule) },
  { path: 'what-is-this', loadChildren: () => import('./pages/what-is-this/what-is-this.module').then((mod) => mod.WhatIsThisModule) },
  { path: 'terms', loadChildren: () => import('./pages/terms/terms.module').then((mod) => mod.TermsModule) },
  { path: 'citations', loadChildren: () => import('./pages/citations/citations.module').then((mod) => mod.CitationsModule) },
  { path: '**', loadChildren: () => import('./pages/default-page/default-page.module').then((mod) => mod.DefaultPageModule) },
];

/** @apiDescription When adding a new page to Routes add the name for
 *                  this page to pathNames array (for GTM)
 *  @apiExample
 *  routes: Routes = [
 *    { path: 'some-path', loadChildren: () => { //callout code here } }
 *  ];
 *
 *  pathNames: Array<PathName> = [
 *    { path: 'some-path', name: 'Your awesome name for GTM or whatever' }
 *  ];
 */
export const pathNames: Array<PathName> = [
  { path: 'cms/:page', name: 'Cms' },
  { path: 'home', name: 'Home' },
  { path: 'article-common-gh', name: 'ArticleCommonPage'},
  { path: 'vsl', name: 'VideoVslPage' },
  { path: 'survey', name: 'SurveyCommonGhPage' },
  { path: 'offer-selector', name: 'OfferSelectorPage' },
  { path: 'two-step-order-form-common', name: 'TwoStepOrderFormCommonPage' },
  { path: 'SecureCart', name: 'SecureCart' },
  { path: 'BHMD_SecureCart', name: 'BHMD_SecureCart' },
  { path: 'order-success-common', name: 'OrderSuccessCommonPage' },
  { path: 'Brand-Receipt_AP', name: 'Brand-Receipt_AP' },
  { path: 'study', name: 'StudyPage' },
  { path: 'policy', name: 'PolicyPage' },
  { path: 'privacy-policy', name: 'PrivacyPolicyPage'},
  { path: 'ccpa', name: 'CcpaPage'},
  { path: 'gdpr', name: 'GdprPage'},
  { path: 'optout', name: 'OptOutPage'},
  { path: 'what-is-this', name: 'WhatIsThisPage' },
  { path: 'terms', name: 'TermsPage' },
  { path: 'citations', name: 'CitationsPage' },

  { path: 'CTUS_12-Unit-DermalRepairComplex_AP', name: 'DermalrepaircomplexUs12unit191009aPage' },
  { path: 'CTUS_3-Unit-DermalRepairComplex_AP', name: 'DermalrepaircomplexUs3unit191009aPage' },
  { path: 'CTUS_6-Unit-DermalRepairComplex_AP', name: 'DermalrepaircomplexUs6unit191009aPage' },
  
  //DEV-7165
  { path: 'CTUS_6-Unit-DermalRepairComplex_200410_AP', name: 'DermalrepaircomplexUs6unit200410aPage' },
  { path: 'DS_3-Unit-DermalRepairComplex_200410_AP', name: 'DermalrepaircomplexDs3unit200410aPage' },
  { path: 'US_6-Unit-LiftFirm-DRC-3-6_200410_AP', name: 'LiftfirmUs6unit200410aPage' },
  { path: 'DS_3-Unit-LiftFirm_200410_AP', name: 'LiftfirmDs3unit200410aPage' },
  { path: 'US_6-Unit-CrepeCorrecting-DRC-3-6_200410_AP', name: 'CrepecorrectingUs6unit200410bPage' },
  { path: 'DS_3-Unit-CrepeCorrecting_200410_AP', name: 'CrepecorrectingDs3unit200410aPage' },
  { path: 'CTUS_3-Unit-DermalRepairComplex_200410_AP', name: 'DermalrepaircomplexUs3unit200410aPage' },
  { path: 'DS_1-Unit-DermalRepairComplex_200410_AP', name: 'DermalrepaircomplexDs1unit200410aPage' },
  { path: 'US_3-Unit-LiftFirm-DRC-1_200410_AP', name: 'LiftfirmUs3unit200410aPage' },
  { path: 'DS_1-Unit-LiftFirm_200410_AP', name: 'LiftfirmDs1unit200410aPage' },
  { path: 'US_3-Unit-CrepeCorrecting-DRC-1_200410_AP', name: 'CrepecorrectingUs3unit200410aPage' },
  { path: 'DS_1-Unit-CrepeCorrecting_200410_AP', name: 'CrepecorrectingDs1unit200410aPage' },
  { path: 'CTUS_12-Unit-DermalRepairComplex_200410_AP', name: 'DermalrepaircomplexUs12unit200410aPage' },
  { path: 'DS_6-Unit-DermalRepairComplex_200410_AP', name: 'DermalrepaircomplexDs6unit200410aPage' },

  { path: 'DS_1-Unit-DermalRepairComplex_AP', name: 'DermalrepaircomplexDs1unit191220aPage' },
  { path: 'DS_6-Unit-DermalRepairComplex_AP', name: 'DermalrepaircomplexDs6unit191220aPage' },
  { path: 'DS_3-Unit-DermalRepairComplex_AP', name: 'DermalrepaircomplexDs3unit191220aPage' },

  { path: 'US_3-Unit-CrepeCorrecting-DRC-1_AP', name: 'CrepecorrectingUs3unit191009aPage' },
  { path: 'US_6-Unit-CrepeCorrecting-DRC-3-6_AP', name: 'CrepecorrectingUs6unit191009bPage' },

  { path: 'DS_1-Unit-CrepeCorrecting_AP', name: 'CrepecorrectingDs1unit191009aPage' },
  { path: 'DS_3-Unit-CrepeCorrecting_AP', name: 'CrepecorrectingDs3unit191009aPage' },

  { path: 'DS_1-Unit-LiftFirm_AP', name: 'LiftfirmDs1unit191226aPage' },
  { path: 'DS_3-Unit-LiftFirm_AP', name: 'LiftfirmDs3unit191226aPage' },

  { path: 'US_3-Unit-LiftFirm-DRC-1_AP', name: 'LiftfirmUs3unit191009aPage' },
  { path: 'US_6-Unit-LiftFirm-DRC-3-6_AP', name: 'LiftfirmUs6unit191009aPage' },

];




/** @apiDescription Set of default pages for navigation (in case when no
 *                  page found in Funnel Configuration)
 *  @apiExample
 *  defaultPages: { [key: string]: PathName } = {
 *    home: { path: 'home', name: 'Default Home Page' },
 *    ordersuccess: { path: 'ordersuccess', name: 'Default Order Success Page' }
 *  };
 */
export const defaultPages: { [key: string]: PathName } = {
  home: { path: 'home', name: 'Home' },
  vsl: { path: 'vsl', name: 'VideoVslPage' },
  survey: { path:'survey', name: 'SurveyCommonGhPage'},
  offerselector: { path:'offer-selector', name: 'OfferSelectorPage'},
  ordersuccess: { path: 'Brand-Receipt_AP', name: 'Brand-Receipt_AP' },
};

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor() {
    setTimeout(() => {
      console.log('routes:', routes);
      console.log('pathNames:', pathNames);
      try {
        const zombieRoutes = routes.filter(
          (item) => !pathNames.find((i) => typeof i !== 'undefined' && i.path === item.path),
        );
        console.log('[GH] routes without pathNames:', zombieRoutes);
      } catch (e) {
        console.log('e1:', e);
      }
      try {
        const zombiePathNames = pathNames.filter(
          (item) => !routes.find((i) => typeof i !== 'undefined' && i.path === item.path),
        );
        console.log('[GH] pathNames without routes:', zombiePathNames);
      } catch (e) {
        console.log('e2:', e);
      }
    }, 300);
  }
}
