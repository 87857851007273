import { FormatHelperBase } from './formatHelper-base';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FormatHelper extends FormatHelperBase {

  constructor() {
    super();
  }
}
