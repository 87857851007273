/**
 * Name: Logger Provider
 * Created: 5/20/2020
 * Author: Valery Haneev <valery.haneev@succraft.com>
 * Synopsis: Service for logging data (both to web browser console and remote server)
 */

import { LoggerBase } from './logger-base';

import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class LoggerProvider extends LoggerBase {
  constructor(
    public http: HttpClient,
    public toastCtrl: ToastController
  ) {
    super(
      http,
      toastCtrl
    );
  }
}
