import { UPSELL_DATA, ORDER_SUCCESS_DATA } from '../../constants/constants';

export class PreviewBase {
  constructor() {}

  addWatermark() {
    const existsWatermark = document.getElementById('watermarkPreviewMode');
    if (existsWatermark) {
      existsWatermark.remove();
    }

    if (document && document.body) {
      const watermark = document.createElement('div');
      watermark.setAttribute('id', 'watermarkPreviewMode');
      watermark.setAttribute('style', `
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        padding: 5px;
        font-size: 13px;
        line-height: 1;
        font-weight: 400;
        text-transform: uppercase;
        text-align: center;
        color: #ffffff;
        background-color: #1976d2;
        opacity: 0.6;
        z-index: 99999;
      `);
      watermark.innerText = 'preview mode';
      document.body.appendChild(watermark);
    }
  }

  getUpsellData() {
    this.addWatermark();
    return UPSELL_DATA;
  }

  getOrderSuccessData() {
    this.addWatermark();
    return ORDER_SUCCESS_DATA;
  }
}
