import { TouchcrApiOrderBase } from './touchcr-api-order-base';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { EventStreamerProvider } from '../event-stream/event-stream';
import { FunnelSettingsProvider } from '../funnel-settings/funnel-settings';
import { UrlsProvider } from '../urls/urls';
import { LoggerProvider } from '../logger/logger';

@Injectable({
  providedIn: 'root',
})
export class TouchcrApiOrderProvider extends TouchcrApiOrderBase {

  constructor(
    public http: HttpClient,
    public eventStreamer: EventStreamerProvider,
    public funnelProvider: FunnelSettingsProvider,
    public urls: UrlsProvider,
    public loggerProvider: LoggerProvider
  ) {
    super(
      http,
      eventStreamer,
      funnelProvider,
      urls,
      loggerProvider
    );
  }
}
