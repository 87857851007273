import { NavControllerBase } from './nav-controller-base';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { EventStreamerProvider } from '../event-stream/event-stream';
import { FunnelSettingsProvider } from '../funnel-settings/funnel-settings';
import { StateProvider } from '../state/state';
import { UrlsProvider } from '../urls/urls';
import { GeneralProvider } from '../general/general';
import { LoggingProvider } from '../../services/logging/logging';

@Injectable({
  providedIn: 'root',
})
export class NavControllerExt extends NavControllerBase {
  constructor(
    public eventStreamer: EventStreamerProvider,
    public router: Router,
    public funnelProvider: FunnelSettingsProvider,
    public stateProvider: StateProvider,
    public urlsProvider: UrlsProvider,
    public generalProvider: GeneralProvider,
    public loggingProvider: LoggingProvider,
  ) {
    super(
      eventStreamer,
      router,
      funnelProvider,
      stateProvider,
      urlsProvider,
      generalProvider,
      loggingProvider,
    );
  }
}
