import { AffParameters } from '../../models/affParameters';

export let queryParams = '';
export let initialQueryParams = '';
export let locationHash = '';

export class UrlsBase {
  PAYPAL_PARAMETERS = ['paypal', 'action', 'paymentId', 'token', 'PayerID'];
  PAYPAL_SUBSCRIPTION_PARAMETERS = ['paypalsubscription', 'action', 'token'];
  PAYPAL_NVP_PARAMETERS = ['paypalnvp', 'action', 'token', 'PayerID'];
  SUCCESS_PARAMETERS = [
    'orderId',
    'orderReferenceId',
    'amount',
    'currencyCode',
    'sellerOrderId',
    'resultCode'
  ];
  FAILURE_PARAMETERS = ['paypal', 'action', 'paymentId', 'token', 'PayerID'];

  constructor() {
    // Initial query parameters are saved to variable used to proceed payments
    this.setInitialQueryParams();
    // Query params are cleared of PayPal payment parameters
    this.clearPayPalParams();
    // Save hash for Amazon Pay
    this.setHash();
  }

  // Extracts query string from provided Url string
  getQueryParamsFromUrl(url: string): string {
    let queryParams = '';

    if (url) {
      // Array containing path as zero element
      // and query parameters on position 1
      const urlArray = url.split('?');

      if (urlArray && urlArray.length > 1) {
        // Replaces path with empty string
        urlArray[0] = '';
        // Joins elements to query string
        queryParams = urlArray.join('?');
      }
    }

    return queryParams;
  }

  getDataFromUrl(url: string) {
    const result = this.getParameterFromUrl({ url, parameter: 'resultCode' });
    const isPayPal = this.getParameterFromUrl({ url, parameter: 'paypal' });
    const isPayPalSubscription = this.getParameterFromUrl({ url, parameter: 'paypalsubscription' });
    const isPayPalNVP = this.getParameterFromUrl({ url, parameter: 'paypalnvp' });
    let dataKeys = [];

    if (isPayPal) {
      dataKeys = this.PAYPAL_PARAMETERS;
    } else if (isPayPalSubscription) {
      dataKeys = this.PAYPAL_SUBSCRIPTION_PARAMETERS;
    } else if (isPayPalNVP) {
      dataKeys = this.PAYPAL_NVP_PARAMETERS;
    } else if (result === 'Success') {
      dataKeys = this.SUCCESS_PARAMETERS;
    } else if (result === 'Failure') {
      dataKeys = this.FAILURE_PARAMETERS;
    }

    const data = {};
    dataKeys.forEach((key) => {
      data[key] = this.getParameterFromUrl({ url, parameter: key });
    });

    data['paymentMethod'] = isPayPal ? 'PayPal' : result ? 'amazon' : 'cart';

    return data;
  }

  getParameterFromUrl({ url = '', parameter = '' }: { url: string, parameter: string }) {
    let parameterStart = url.indexOf(`${parameter}=`);
    if (parameterStart === -1) return '';

    parameterStart = parameterStart + parameter.length + 1;

    const parameterEnd = url.indexOf('&', parameterStart);
    if (parameterEnd === -1) {
      return url.substring(parameterStart);
    }

    return url.substring(parameterStart, parameterEnd);
  }

  getAffParamsFromUrl(url: string): AffParameters {
    return {
      utmSource: this.getParameterFromUrl({ url, parameter: 'utm_source' }),
      utmMedium: this.getParameterFromUrl({ url, parameter: 'utm_medium' }),
      utmCampaign: this.getParameterFromUrl({ url, parameter: 'utm_campaign' }),
      utmCampaignId: this.getParameterFromUrl({ url, parameter: 'utm_campaign_id' }),
      utmContent: this.getParameterFromUrl({ url, parameter: 'utm_content' }),
      utmTerm: this.getParameterFromUrl({ url, parameter: 'utm_term' }),
      affId: this.getParameterFromUrl({ url, parameter: 'affid' }),
      offId: this.getParameterFromUrl({ url, parameter: 'offid' }),
      subId1: this.getParameterFromUrl({ url, parameter: 'subid1' }),
      subId2: this.getParameterFromUrl({ url, parameter: 'subid2' }),
      subId3: this.getParameterFromUrl({ url, parameter: 'subid3' }),
      subId4: this.getParameterFromUrl({ url, parameter: 'subid4' }),
      subId5: this.getParameterFromUrl({ url, parameter: 'subid5' }),
    };
  }

  addParamToQuery(query: string, paramKey: string, paramValue: string) {
    if (!paramKey || !paramValue) return query;
    if (this.getParameterFromUrl({ url: query, parameter: paramKey })) return query;
    if (!query) return `?${paramKey}=${paramValue}`;
    return `${query}&${paramKey}=${paramValue}`;
  }

  changeParamInQuery(query: string, paramKey: string, paramValue: string) {
    if (!query || !paramKey || !paramValue) return;
    const newQuery = this.removeParamFromQuery(query, paramKey);
    return this.addParamToQuery(newQuery, paramKey, paramValue);
  }

  removeParamFromQuery(query: string, paramKey: string) {
    if (!query) return;
    if (!paramKey) return query;
    const paramValue = this.getParameterFromUrl({ url: query, parameter: paramKey });
    if (!paramValue) return query;
    const keyVal = `${paramKey}=${paramValue}`;
    const startIndex = query.indexOf(keyVal);
    if (startIndex === -1) return;
    query.substr(startIndex - 1, 1);
    if (query[startIndex + keyVal.length] && query[startIndex + keyVal.length] === '&') {
      return query.substr(0, startIndex) + query.substr(startIndex + 1 + keyVal.length);
    } else {
      return query.substr(0, startIndex - 1) + query.substr(startIndex + keyVal.length);
    }
  }

  changeState(queryParams: string = '') {
    this.setQueryParams(queryParams);
    const url = `${location.origin}${location.pathname}${queryParams}`;
    history.replaceState(null, null, url);
  }

  clearPayPalParams() {
    let query = this.getInitialQueryParams();

    if (this.getParameterFromUrl({ url: query, parameter: 'paypal'}))
      query = this.removeParamFromQuery(query, 'paypal');
    if (this.getParameterFromUrl({ url: query, parameter: 'paypalsubscription'}))
      query = this.removeParamFromQuery(query, 'paypalsubscription');
    if (this.getParameterFromUrl({ url: query, parameter: 'paypalnvp'}))
      query = this.removeParamFromQuery(query, 'paypalnvp');
    if (this.getParameterFromUrl({ url: query, parameter: 'action'}))
      query = this.removeParamFromQuery(query, 'action');
    if (this.getParameterFromUrl({ url: query, parameter: 'paymentId'}))
      query = this.removeParamFromQuery(query, 'paymentId');
    if (this.getParameterFromUrl({ url: query, parameter: 'token'}))
      query = this.removeParamFromQuery(query, 'token');
    if (this.getParameterFromUrl({ url: query, parameter: 'PayerID'}))
      query = this.removeParamFromQuery(query, 'PayerID');

    this.setQueryParams(query);
  }

  getQueryParams() {
    return queryParams;
  }

  setQueryParams(str) {
    queryParams = str;
  }

  // Returns initial query parameters obtained on app loading
  getInitialQueryParams() {
    return initialQueryParams;
  }

  // Sets initial query parameters obtained on app loading
  setInitialQueryParams() {
    initialQueryParams = window.location.search;
  }

  // Replaces initial query parameters with current QueryParams value
  replaceInitialQueryParams() {
    initialQueryParams = queryParams;
  }

  // Returns location hash for Amazon Pay feature
  getHash() {
    return locationHash;
  }

  // Sets location hash needed for Amazon Pay feature
  setHash() {
    locationHash = window.location.hash;
  }

  // Clears location hash for Amazon Pay feature
  clearHash() {
    locationHash = '';
  }

  getQueryStringAsObject(query) {
    const queryString = query && query[0] === '?' ? query.substr(1, query.length) : query;
    if (!queryString) {
      return null;
    }
    const queryArr = queryString.split('&');
    if (!queryArr) {
      return null;
    }
    const resultObject = {};
    queryArr.forEach(item => {
      const pair = item.split('=');
      if (pair && pair.length > 1) {
        resultObject[pair[0]] = pair[1];
      }
    });
    return resultObject;
  }
}
