import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError} from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { LoggerProvider } from '../logger/logger';

import { AffParameters } from '../../models/affParameters';
import { Offer } from '../../models/offer';
import { LogData } from '../../models/logData';

const API_URL = window['process_env'].API_URL;
const OFFERS_BY_SF_ID = 'offers/bysfid/';
const SET_PARAMETERS = 'affiliate/setaffparameters';

export class TouchcrApiAffiliateBase {
  constructor(
    public http: HttpClient,
    public loggerProvider: LoggerProvider
  ) {}

  setAffParameters(parameters: AffParameters) {
    let needSet;
    for (const parameter in parameters) {
      if (parameters[parameter] !== null && parameters[parameter] !== '') {
        needSet = parameter;
      }
    }
    parameters['referralUrl'] = document.referrer.split('?')[0];
    parameters['landingUrl'] = window.location.href.split('?')[0];
    parameters['salesFunnel'] = parameters.offId ? parameters.offId : 'Funnel';
    if (!window.sessionStorage.getItem('affiliateInfo')) {
      this.saveAffParamsToSessionStorage(parameters);
      return this.http.post(`${API_URL}${SET_PARAMETERS}`, parameters).pipe(
        catchError((error) => {
          this.loggerProvider.logToServer({
            level: 'error',
            message: 'Error on setAffParameters(): ',
            logObject: { error }
          } as LogData);
          return of(error && error.error && error.error.error);
        }),
      );
    }
    if (!needSet) return of();
    this.saveAffParamsToSessionStorage(parameters);
    return this.http.post(`${API_URL}${SET_PARAMETERS}`, parameters).pipe(
      catchError((error) => {
        this.loggerProvider.logToServer({
          level: 'error',
          message: 'Error on setAffParameters() (needSet)): ',
          logObject: { error }
        } as LogData);
        return throwError(error && error.error && error.error.error);
      }),
    );
  }

  saveAffParamsToSessionStorage(parameters) {
    if (!parameters) return;
    window.sessionStorage.setItem('affiliateInfo', JSON.stringify(parameters));
  }

  getOffersBySalesforceId(sfId: string): Observable<Offer> {
    return this.http.get<Offer>(`${API_URL}${OFFERS_BY_SF_ID}${sfId}`).pipe(
      map((offer: Offer) => {
        if (
          offer.product &&
          offer.product.image &&
          offer.product.image[0] &&
          offer.product.image[0].imageUrl
        ) {
          offer.imageUrl = offer.product.image[0].imageUrl;
        }
        return offer;
      }),
      catchError((error) => {
        return of(error && error.error && error.error.error);
      }),
    );
  }
}
