export class FormatHelperBase {

  protected static readonly ExtensionRegex = /^(x)/gi;
  protected static readonly PhoneNumberCharacterRegex = /^\s|\.|-|\(|\)|\+/gi;

  constructor() {}

  public cleanPhoneNumber(phoneNumber: string) {
    let cleansed = phoneNumber;
    // check for extension formats: x999
    // check for -,.()+
    cleansed = FormatHelperBase.PhoneNumberCharacterRegex.test(cleansed)
      ? cleansed.replace(FormatHelperBase.PhoneNumberCharacterRegex, '')
      : cleansed;

    return cleansed;
  }
}
