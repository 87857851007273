import { NgZone } from '@angular/core';
import { LoadingOptions } from '@ionic/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { BrowserProvider } from '../browser/browser';
import { TouchcrApiOrderProvider } from '../touchcr-api-order/touchcr-api-order';
import { SpinnerProvider } from '../spinner/spinner';
import { FunnelSettingsProvider } from '../funnel-settings/funnel-settings';
import { UrlsProvider } from '../urls/urls';
import { CookiesProvider } from '../cookies/cookies';
import { NavControllerExt } from '../navigation-controller-extension/nav-controller-extension';
import { StateProvider } from '../state/state';
import { HandlerProvider } from '../handler/handler';
import { LoggerProvider } from '../logger/logger';

import { FormatHelper } from '../../utils/formatHelper/formatHelper';

import { ErrorOptions } from '../../models/errorOptions';
import { LogData } from '../../models/logData';

import {
  FACEBOOK,
  WEBVIEW,
  WEBKIT
} from '../../constants/constants';

declare var OffAmazonPayments: any;

const BRAND = window['process_env'].BRAND;
const AMAZON_PAY_ENABLED = window['process_env'].AMAZON_IS_ENABLED;

export class AmazonPayBase {

  protected _window: any = window;
  protected _deviceInfo;
  protected _loginOptions: any = { scope: 'profile payments:widget payments:shipping_address payments:billing_address', popup: true };
  protected _orderReferenceId;
  protected _billingAgreementId;
  protected _accessToken;
  protected _isAuthorized: BehaviorSubject<boolean> = new BehaviorSubject(false);
  protected _customerInformation: BehaviorSubject<any> = new BehaviorSubject(null);
  protected _authRequest;
  protected _amazonLoginEmail;
  protected _buttonList;
  protected _isUpsell: boolean = false;
  protected _isSubscription: BehaviorSubject<boolean> = new BehaviorSubject(false);
  protected _consentStatus: BehaviorSubject<boolean> = new BehaviorSubject(false);
  protected _isInitialLoad: boolean = false;

  // Public Access to the Amazon SDK. Using this will ensure it's loaded properly
  public sdk;
  // Public observable to listen to successful amazon authorizations
  public isAuthorized: Observable<boolean> = this._isAuthorized.asObservable();
  // Public observable customer object, listen for changes on customer information while user is interacting with widgets
  public customerInformation: Observable<any> = this._customerInformation.asObservable();
  // Public observable consentStatus, listen for changes when the user clicks on the consent widget.
  public consentStatus: Observable<boolean> = this._consentStatus.asObservable();

  constructor(
    protected navCtrl: NavControllerExt,
    protected _zone: NgZone,
    protected _tcrApiOrder: TouchcrApiOrderProvider,
    protected _browser: BrowserProvider,
    protected _format: FormatHelper,
    protected _spinner: SpinnerProvider,
    protected _funnelSettings: FunnelSettingsProvider,
    protected _cookies: CookiesProvider,
    protected _urls: UrlsProvider,
    protected _stateProvider: StateProvider,
    protected _handler: HandlerProvider,
    protected _loggerProvider: LoggerProvider
  ) {
    this._deviceInfo = '';
    this._handleCookieAndRedirect();
  }

  /**
   * init()
   *
   * @param buttonList array of button configurations
   * @param renderAddressBook boolean to render the address book on successful authorization from the user
   * @param renderWallet boolean to render the wallet on successful authorization
   *
   * if renderAddressBook is false, and renderWallet is true, you must set setIsUpsell to true to get only the wallet to render
   */
  public init(buttonList: any = false, renderAddressBook: boolean = false, renderWallet: boolean = false) {

    this._loggerProvider.logToConsole({
      level: 'log',
      message: ':::     TCR AmazonPay Provider     :::'
    } as LogData);
    if (!this._browser.isAmazonPaySupported()) {
      this._sendCustomFunnelEvent('AmazonPay:SupportError', { error: 'Unsupported browser' });
      return;
    }

    const device = this._browser.getDeviceInfo();
    this._handleCookieAndRedirect();

    if (
      device
      && device.device !== 'Desktop'
      && device.browser
      && ( // Check for In-App browsers
        device.browser.indexOf(FACEBOOK) > -1
        || device.browser.indexOf(WEBVIEW) > -1
        || device.browser.indexOf(WEBKIT) > -1
      )
    ) {
      this._loggerProvider.logToConsole({
        level: 'log',
        message: ':: In-App browser detected. Enabling redirect mode ::'
      } as LogData);
      this._loginOptions.popup = false;
    }
    if (buttonList) {
      this._buttonList = buttonList;
    }

    if (this._isAmazonPayAvailable()) {
      this.initAmazon(renderAddressBook, renderWallet);
    } else {
      this._loggerProvider.logToConsole({
        level: 'log',
        message: ':: AmazonPay is not available on the window object. Setting init to run on onAmazonLoginReady()'
      } as LogData);
      this._window.onAmazonLoginReady = () => {
        this.initAmazon(renderAddressBook, renderWallet);
      };
    }
  }

  protected initAmazon(renderAddressBook: boolean = false, renderWallet: boolean = false) {
    this._loggerProvider.logToConsole({
      level: 'log',
      message: ':: Setting Amazon Login Client Id'
    } as LogData);
    this._window.amazon.Login.setClientId(this._window.process_env.AMAZON_LWA_CLIENT_ID);
    this.isAuthorized.subscribe((amazonPayAuthorized) => {
      if (!amazonPayAuthorized) {
        this.renderLoginButtons(this._buttonList, renderAddressBook, renderWallet);
      }
    });
    if (this._isInitialLoad && this._accessToken) {
      this._loggerProvider.logToConsole({
        level: 'log',
        message: ':: Detected AmazonPay Cookie and Access Token'
      } as LogData);
      this._loggerProvider.logToConsole({
        level: 'log',
        message: ':: Rendering AmazonPay Widgets'
      } as LogData);
      this._isAuthorized.next(true);
      this._window.amazon.Login.retrieveProfile(this._accessToken, (r) => {
        this._amazonLoginEmail = r.profile.PrimaryEmail;
        for (const button of this._buttonList) {
          if (!this._isUpsell && this.renderAddressBook) {
            this.renderAddressBook(button, renderWallet);
          }
          if (this._isSubscription.getValue() && !this._isUpsell) {
            this._isSubscription.subscribe((isSubscription) => {
              this._loggerProvider.logToConsole({
                level: 'log',
                message: ':: Subscription change',
                logObject: { sub: isSubscription }
              } as LogData);
              this._loggerProvider.logToConsole({
                level: 'log',
                message: ':: Rendering Consent Widget'
              } as LogData);
              this.renderConsent(button);
            });
          }
        }
      });
      this._window.amazon.Login.setUseCookie(true);
    }
  }

  /**
   *
   */
  public isAmazonPayEnabled(): boolean {
    return AMAZON_PAY_ENABLED;
  }

  /**
   * logout()
   * Log the user out of AmazonPay sdk
   */
  public logout(): void {
    this._window.amazon.Login.Logout();
  }

  /**
   * getOrderReferenceId()
   * @returns string of the orderReferenceId that was created
   */
  public getOrderReferenceId(): string {
    return this._orderReferenceId;
  }

  /**
   * getBillingAgreementId()
   * @returns string of the _billingAgreementId that was created
   */
  public getBillingAgreementId(): string {
    return this._billingAgreementId;
  }

  /**
   * getAccessToken()
   * @returns string of the access token from a successful authorization
   */
  public getAccessToken(): string {
    return this._accessToken;
  }

  /**
   * setIsSubscription()
   * @param isSubscription boolean sets the current interaction of amazon pay to subscription mode
   */
  public setIsSubscription(isSubscription: boolean): void {
    this._isSubscription.next(isSubscription);
    this._loginOptions.state = 'isSubscription=1';
  }

  /**
   * setButtonList()
   * @param buttonList object Button object that holds all button configurations
   */
  public setButtonList(buttonList: any) {
    this._buttonList = buttonList;
    if (this._isAmazonPayAvailable()) {
      OffAmazonPayments.Widgets.Mediator.Reset();
    }
  }

  /**
   * setIsUpsell()
   * setting this on the upsell page will allow the user to only render the wallet for upsell purchases
   * @param isUpsell boolean sets whether the current page/interaction with amazon pay is on an upsell page or not.
   */
  public setIsUpsell(isUpsell: boolean) {
    this._isUpsell = isUpsell;
  }

  /**
   * renderLoginButtons()
   * @param buttonList array of button configurations
   * @param renderAddressBook boolean to render the address book on successful authorization from the user
   * @param renderWallet boolean to render the wallet on successful authorization
   *
   * Will render out login buttons for the passed configurations
   * if renderAddressBook is false, and renderWallet is true, you must set setIsUpsell to true to get only the wallet to render
   */
  public renderLoginButtons(buttonList, renderAddressBook: boolean = false, renderWallet: boolean = false): void {
    if (this._isAmazonPayAvailable()) {
      this._loggerProvider.logToConsole({
        level: 'log',
        message: ':: Rendering Amazon Buttons'
      } as LogData);
      this._loggerProvider.logToConsole({
        level: 'log',
        message: ':: Button List: ',
        logObject: buttonList
      } as LogData);
      OffAmazonPayments.Widgets.Mediator.Reset();
      for (let i = 0; i < this._buttonList.length; i++) {
        const amazonButton = document.getElementById(buttonList[i].buttonId);
        if (amazonButton && !amazonButton.childElementCount) {
          new OffAmazonPayments.Button(this._buttonList[i].buttonId, this._window.process_env.AMAZON_SELLER_ID, {
            type: 'PwA',
            authorization: async () => {
              this._window.amazon.Login.setClientId(this._window.process_env.AMAZON_LWA_CLIENT_ID);
              if (this._loginOptions.popup) {
                this._authRequest = this._window.amazon.Login
                  .authorize(
                    this._loginOptions,
                    response => this._authorizationCallback(response, i, renderAddressBook, renderWallet)
                  );
              } else {
                const redirect = (location.pathname + location.search).substr(1);
                this._cookies.setCookie('tcr_isSub', '', -1);
                this._cookies.setCookie('tcr_isSub', this._isSubscription.getValue(), 1);
                this._loginOptions['state'] = redirect;
                this._authRequest = this._window.amazon.Login
                  .authorize(this._loginOptions, `${location.protocol}//${location.host}/amazon-return`);
              }
            },
            onError: (error) => {
              this._consentStatus.next(false);
              this._sendCustomFunnelEvent(
                'AmazonPay:LoginButtonError',
                {
                  message: 'LoginButtonError',
                  errorCode: error.getErrorCode(),
                  errorMessage: error.getErrorMessage()
                });
              this._handler.handleError({
                error,
                methodName: 'renderLoginButtons',
                errorMessage: error.getErrorMessage()
              } as ErrorOptions);
            }
          });
        }
      }
    } else {
      this._window.onAmazonLoginReady = () => {
        this.renderLoginButtons(this._buttonList, renderAddressBook, renderWallet);
      };
      return;
    }
  }

  /**
   * renderAddressBook()
   * Will render out the passed button configuration's wallet widget
   * If renderWallet is true, when the address book is rendered and ready, the wallet will be rendered for
   * the same button widget configuration
   * @param button button configuration
   * @param renderWallet boolean will render the wallet after the address button is rendered successfully.
   * @returns void
   */
  public renderAddressBook(button: any, renderWallet: boolean = false): void {
    const btnConfig = {
      sellerId: this._window.process_env.AMAZON_SELLER_ID,
      design: {
        designMode: 'responsive'
      },
      onReady: async (orderReference) => {
        this._loggerProvider.logToConsole({
          level: 'log',
          message: ':: Rendering Amazon Address Book'
        } as LogData);
        if (this._isSubscription.getValue()) {
          this._loggerProvider.logToConsole({
            level: 'log',
            message: ':: Subscription Detected AddressWidget will use BillingAgreement'
          } as LogData);
          this._billingAgreementId = orderReference.getAmazonBillingAgreementId();
          await this.renderWallet(button, this._billingAgreementId);
          this.renderConsent(button);
        } else {
          this._orderReferenceId = orderReference.getAmazonOrderReferenceId();
          await this.renderWallet(button, this._orderReferenceId);
        }
      },
      onAddressSelect: (orderReference) => {
        this._getCustomerAddressInformation();
      },
      onError: (error) => {
        this._consentStatus.next(false);
        this._sendCustomFunnelEvent(
          'AmazonPay:WidgetError',
          {
            widget: 'AddressBook',
            errorCode: error.getErrorCode(),
            errorMessage: error.getErrorMessage()
          });
        this._handler.handleError({
          error,
          methodName: 'renderAddressBook',
          errorMessage: error.getErrorMessage()
        } as ErrorOptions);
      }
    };

    if (this._isSubscription.getValue()) {
      this._loggerProvider.logToConsole({
        level: 'log',
        message: ':: Subscription Detected AddressWidget will use BillingAgreement'
      } as LogData);
      btnConfig['agreementType'] = 'BillingAgreement';
    }
    new OffAmazonPayments.Widgets.AddressBook(btnConfig).bind(button.addressId);
  }

  /**
   * renderWallet()
   * If orderReferenceId is passed in, the referenceId will be configured on the widget.
   * If orderReferenceId is blank, the wallet widget will create it's own orderReferenceId
   * @param button button configuration
   * @param orderReferenceId string the order reference id for amazon
   */
  public async renderWallet(button: any, orderReferenceId: string = '') {
    const btnConfig = {
      sellerId: this._window.process_env.AMAZON_SELLER_ID,
      design: {
        // designMode: this._browser.isMobile !== 'Desktop' ? 'smartphoneCollapsible' : 'responsive'
        designMode: 'responsive'
      },
      onPaymentSelect: (orderReference) => {},
      onError: (error) => {
        this._consentStatus.next(false);
        this._sendCustomFunnelEvent(
          'AmazonPay:WidgetError',
          {
            widget: 'Wallet',
            errorCode: error.getErrorCode(),
            errorMessage: error.getErrorMessage()
          });
        this._handler.handleError({
          error,
          methodName: 'renderWallet',
          errorMessage: error.getErrorMessage()
        } as ErrorOptions);
      }
    };
    if (orderReferenceId !== '') {
      if (this._isSubscription.getValue()) {
        this._loggerProvider.logToConsole({
          level: 'log',
          message: ':: Subscription Detected Wallet will use BillingAgreement'
        } as LogData);
        btnConfig['amazonBillingAgreementId'] = this._billingAgreementId;
      } else {
        this._loggerProvider.logToConsole({
          level: 'log',
          message: ':: WalletWidget called after AddressBookWidget, using orderReferenceId from AddressBookWidget'
        } as LogData);
        btnConfig['amazonOrderReferenceId'] = this._orderReferenceId;
      }
    } else {
      await this._spinner.enable({ message: 'Loading Amazon Wallet...' } as LoadingOptions);
      this._loggerProvider.logToConsole({
        level: 'log',
        message: ':: WalletWidget called by itself, creating own orderReferenceId'
      } as LogData);
      btnConfig['onOrderReferenceCreate'] = (orderReference: any) => {
        this._orderReferenceId = orderReference.getAmazonOrderReferenceId();
      };
    }
    this._changePlaceOrderButtonStatus(button);
    new OffAmazonPayments.Widgets.Wallet(btnConfig).bind(button.walletId);
    await this._spinner.disable();
  }

  /**
   * renderConsent()
   * Will render the consent widget for amazon subscription payments
   * @param button button configuration
   */
  public renderConsent(button: any): void {
    const btnConfig = {
      sellerId: this._window.process_env.AMAZON_SELLER_ID,
      amazonBillingAgreementId: this._billingAgreementId,
      design: {
        designMode: 'responsive'
      },
      onReady: (billingAgreementStatus) => {
        const status = billingAgreementStatus.getConsentStatus() === 'true' ? true : false;
        this._consentStatus.next(status);
      },
      onConsent: (billingAgreementStatus) => {
        const status = billingAgreementStatus.getConsentStatus() === 'true' ? true : false;
        this._consentStatus.next(status);
      },
      onError: (error) => {
        this._consentStatus.next(false);
        this._sendCustomFunnelEvent(
          'AmazonPay:WidgetError',
          {
            widget: 'Consent',
            errorCode: error.getErrorCode(),
            errorMessage: error.getErrorMessage()
          });
        this._handler.handleError({
          error,
          methodName: 'renderConsent',
          errorMessage: error.getErrorMessage()
        } as ErrorOptions);
      }
    };
    new OffAmazonPayments.Widgets.Consent(btnConfig).bind(button.consentId);
  }

  /**
   * TODO: Implement pay logic to condense code
   */
  public pay(amazonOrderBody: any) {
  }

  public getRedirectUrl() {
    return this._getURLParameter('state', this._urls.getHash());
  }

  /**
   * @protected _authorizationCallback(responseFromWidget)
   */
  protected async _authorizationCallback(response, i, renderAddressBook, renderWallet) {
    if (!response.error) {
      this._loggerProvider.logToConsole({
        level: 'log',
        message: ':: AmazonPay Authorization Successfull'
      } as LogData);
      this._accessToken = response.access_token;

      this._window.amazon.Login.retrieveProfile((r) => {
        this._amazonLoginEmail = r.profile.PrimaryEmail;
      });

      this._changePlaceOrderButtonStatus(this._buttonList[i]);

      if (renderAddressBook) {
        this.renderAddressBook(this._buttonList[i], renderWallet);
      }

      if (this._isUpsell && renderWallet) {
        await this.renderWallet(this._buttonList[i]);
      }
      this._zone.run( () => {
        this._isAuthorized.next(true);
      });
    } else {
      this._sendCustomFunnelEvent(
        'AmazonPay:AuthorizationError',
        {
          message: 'AmazonPay User Authorization Failed',
          amazonResponse: response
        });
      this._handler.handleError({
        error: response.error,
        methodName: '_authorizationCallback',
        errorMessage: 'AmazonPay User Authorization Failed'
      } as ErrorOptions);
    }
  }

  /**
   * @protected _getCustomerAddressInformation()
   * Calls out to the TCR Order API to obtain customer information from Amazon API
   * When successful information comes back from amazon API, the CustomerInformation object will be updated
   * any listeners on that object will receive the updated information
   * @returns void
   */
  protected async _getCustomerAddressInformation() {
    await this._spinner.enable({ message: 'Processing customer information' } as LoadingOptions);
    const body = {};
    body['brand'] = BRAND;
    body['accessToken'] = this.getAccessToken();
    if (this._isSubscription.getValue()) {
      body['billingAgreementId'] = this.getBillingAgreementId();
      this._loggerProvider.logToConsole({
        level: 'log',
        message: ':: Subscription Detected getCustomerInformation will use getInfoAmazonSub ApiEndpoint'
      } as LogData);
      this._tcrApiOrder.getInfoAmazonSub(body).toPromise()
        .then(async (result: any) => {
          result.email = this._amazonLoginEmail;
          result.formattedPhoneNumber = this._format.cleanPhoneNumber(result.phone);
          this._customerInformation.next(result);
          await this._spinner.disable();
        })
        .catch(async (error) => {
          await this._spinner.disable();
          this._sendCustomFunnelEvent(
            'AmazonPay:ApiError',
            {
              endpoint: 'getInfoAmazonSub',
              rawError: error
            });
          this._handler.handleError({
            error,
            methodName: 'getInfoAmazonSub',
          } as ErrorOptions);
        });
    } else {
      body['orderReferenceId'] = this.getOrderReferenceId();
      this._tcrApiOrder.getInfoAmazon(body).toPromise()
        .then(async (result: any) => {
          result.email = this._amazonLoginEmail;
          result.formattedPhoneNumber = this._format.cleanPhoneNumber(result.phone);
          this._customerInformation.next(result);
          await this._spinner.disable();
        })
        .catch(async (error) => {
          await this._spinner.disable();
          this._sendCustomFunnelEvent(
            'AmazonPay:ApiError',
            {
              endpoint: 'getInfoAmazon',
              rawError: error
            });
          this._handler.handleError({
            error,
            methodName: 'getInfoAmazon',
          } as ErrorOptions);
        });
    }
  }

  /**
   * @protected _changePlaceOrderButtonStatus()
   * changes a boolean on the matched button in button list to faciliate showing and hiding buttons.
   * @param button button config object
   */
  protected _changePlaceOrderButtonStatus(button) {
    this._zone.run( () => {
      this._buttonList.filter((btn) => {
        btn.isPlaceOrder = button.buttonId !== btn.buttonId;
      });
    });
  }

  /**
   * @protected _isAmazonPayAvailable()
   * method to check that amazon pay is available on the window object
   * @returns boolean
   */
  protected _isAmazonPayAvailable(): boolean {
    if (!this._window && !this._window.amazon) {
      this._loggerProvider.logToConsole({
        level: 'log',
        message: ':: Amazon Pay is unavailable'
      } as LogData);
    }
    return this._window && this._window.amazon;
  }

  protected _handleCookieAndRedirect() {
    const access_token = this._getURLParameter('access_token', this._urls.getHash());
    if (access_token) {
      this._loggerProvider.logToConsole({
        level: 'log',
        message: ':: AmazonPay found access_token and redirecting...'
      } as LogData);
      this._cookies.setCookie('amazon_Login_accessToken', access_token, 1);
      this._isInitialLoad = true;
    } else if (this._isInitialLoad) {
      const cookie_access_token = this._cookies.getCookie('amazon_Login_accessToken');
      if (cookie_access_token && typeof cookie_access_token === 'string' && cookie_access_token.match(/^Atza/)) {
        this._loggerProvider.logToConsole({
          level: 'log',
          message: ':: Amazon access cookie found, setting accessToken'
        } as LogData);
        const isSubCookie = this._cookies.getCookie('tcr_isSub') === 'true';
        if (isSubCookie) {
          this._isSubscription.next(isSubCookie);
          this._cookies.setCookie('tcr_isSub', '', -1);
        }
        this._accessToken = cookie_access_token;
      }
    }
  }

  protected _getURLParameter(name, source) {
    return decodeURIComponent((new RegExp('[?|&|#]' + name + '=' +
      '([^&]+?)(&|#|;|$)').exec(source) || [,""])[1].replace(/\+/g,
      '%20')) || null;
  }

  protected _sendCustomFunnelEvent(eventType: string, payload: any): void {
    const mergedPayload = Object.assign(payload, this._browser.getDeviceInfo());
    this._loggerProvider.logToConsole({
      level: 'log',
      message: '::: TCR AmazonPay Provider Custom Funnel Debug :::',
      logObject: {
        message: ':: Sending a custom funnel event',
        eventType,
        mergedPayload
      }
    } as LogData);
    this._funnelSettings.sendCustomStats(eventType, mergedPayload);
  }
}
