import { Component } from '@angular/core';

import { Storage } from '@ionic/storage';

import { UrlsProvider } from './services/urls/urls';
import { TouchcrApiAffiliateProvider } from './services/touchcr-api-affiliate/touchcr-api-affiliate';
import { FunnelSettingsProvider } from './services/funnel-settings/funnel-settings';
import { DESTINATION_ID } from './services/funnel-settings/funnel-settings-base';
import { CONTENT_ID } from './services/funnel-settings/funnel-settings-base';
import { NavControllerExt } from './services/navigation-controller-extension/nav-controller-extension';
import { GeneralProvider } from './services/general/general';
import { AmazonPay } from './services/amazon-pay/amazonPay';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent {

  constructor(
    public urls: UrlsProvider,
    public tcrApiAffiliate: TouchcrApiAffiliateProvider,
    public storage: Storage,
    public funnelProvider: FunnelSettingsProvider,
    public navCtrl: NavControllerExt,
    public general: GeneralProvider,
    public amazonPay: AmazonPay
  ) {
    this.general.enableBackButton();

    const redirectUrl = this.amazonPay.getRedirectUrl();
    if (redirectUrl) {
      const redirectUrlArray = redirectUrl.split('?');
      if (redirectUrlArray.length) {
        redirectUrlArray[0] = '';
        const queryParams = redirectUrlArray.join('?');
        this.urls.setQueryParams(queryParams);
        this.urls.replaceInitialQueryParams();
      }
    }

    let isCreated: boolean = false;
    let sessionId = this.urls.getParameterFromUrl({ url: this.urls.getQueryParams(), parameter: 'sessionid' });
    if (!sessionId) {
      isCreated = true;
      sessionId = this.general.getNewSession();
    }

    const contentId = this.urls.getParameterFromUrl({
      url: this.urls.getQueryParams(),
      parameter: CONTENT_ID,
    });
    if (contentId) {
      this.funnelProvider.setContentId(contentId);
    }

    const affParameters = this.urls.getAffParamsFromUrl(this.urls.getQueryParams());
    this.tcrApiAffiliate.setAffParameters(affParameters).toPromise();

    const destId = this.urls.getParameterFromUrl({
      url: this.urls.getQueryParams(),
      parameter: DESTINATION_ID,
    });

    if (destId) {
      this.funnelProvider.setDestinationIdValue(destId);
    }

    this.funnelProvider.setSessionId({ sessionId, isCreated });
  }

  onActivate(e, outlet) { // TOUC-2152 user does see top of page in FF mobile.  Force scroll to top onActivate of the router-outlet
    try {

      document.querySelector('ion-content').scrollIntoView({ block: 'start', behavior: 'smooth' });
      setTimeout(() => {
        let ics = document.querySelectorAll('ion-content');
        for (let i = 0; i < ics.length; i++) {
          ics[i].scrollToTop();
          if(typeof ics[i].scrollTo !== 'undefined')
           ics[i].scrollTo(0, 0);
        }
        window.scrollTo(0, 0);
      }, 100);
    } catch (e) {
      console.log("error scrolling content to top " + e);
    }
  }
}
