import { HandlerBase } from './handler-base';

import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

import { LoggerProvider } from '../logger/logger';

@Injectable({
  providedIn: 'root',
})
export class HandlerProvider extends HandlerBase {
  constructor(
    public toastCtrl: ToastController,
    public loggerProvider: LoggerProvider
  ) {
    super(
      toastCtrl,
      loggerProvider
    );
  }
}
