import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { EventStreamerProvider } from '../event-stream/event-stream';
import { FunnelSettingsProvider } from '../funnel-settings/funnel-settings';
import { UrlsProvider } from '../urls/urls';
import { LoggerProvider } from '../logger/logger';

import { Order } from '../../models/order';
import { LogData } from '../../models/logData';

const API_URL = window['process_env'].API_URL;
const GOOGLE_APPLE_PAYMENT_AGENT = window['process_env'].GOOGLE_APPLE_PAYMENT_AGENT;

const GET_ALL_ORDERS = 'orders/all';
const PAY_WITHOUT_LOGIN = 'orders/createguest';
const CREATE_TRANSACTION_V2 = 'v2/orders/createTransaction';
const PAY_WITH_LOGIN = 'orders/create';
const CREATE_WITH_LOGIN = 'orders/createorder';
const CREATE_WITHOUT_LOGIN = 'orders/setorderguest';
const CREATE_TRANSACTION = 'orders/createtransaction';
const CREATE_PAYPAL_PAYMENT = 'orders/createpaypalpayment';
const EXECUTE_PAYPAL_PAYMENT = 'orders/executepaypalpayment';
const CREATE_PAYPAL_SUBSCRIPTION = 'orders/createpaypalsubscription';
const EXECUTE_PAYPAL_SUBSCRIPTION = 'orders/executepaypalsubscription';
const CANCEL_PAYPAL_SUBSCRIPTION = 'orders/cancelpaypalsubscription';
const DO_EXPRESS_CHECKOUT = 'orders/doexpresscheckout';
const CREATE_RECURRING_PAYMENT = 'orders/createrecurringpayment';
const SET_EXPRESS_CHECKOUT = 'orders/setexpresscheckout';
const GET_EXPRESS_CHECKOUT_DETAIL = 'orders/getexpresscheckoutdetail';
const PAY_WITH_AMAZON = 'orders/payamazon';
const PAY_WITH_AMAZONSUB = 'orders/payamazonsub';
const GET_INFO_AMAZON = 'orders/getInfoAmazon';
const GET_INFO_AMAZONSUB = 'orders/getInfoAmazonSub';
const CAPTURE_AMAZON = 'orders/captureamazon';

export class TouchcrApiOrderBase {
  constructor(
    public http: HttpClient,
    public eventStreamer: EventStreamerProvider,
    public funnelProvider: FunnelSettingsProvider,
    public urls: UrlsProvider,
    public loggerProvider: LoggerProvider
  ) {}

  getAllOrders() {
    return this.http.get(`${API_URL}${GET_ALL_ORDERS}`).pipe(
      catchError((error) => {
        this.loggerProvider.logToServer({
          level: 'error',
          message: 'Error on getAllOrders(): ',
          logObject: { error }
        } as LogData);
        return throwError(error && error.error && error.error.error);
      }),
    );
  }

  payProductWithoutLogin(body, v2 = false): Observable<Order> {
    body.statsInfo = this.funnelProvider.getStats();
    const url = !v2
      ? `${API_URL}${PAY_WITHOUT_LOGIN}`
      : `${API_URL.slice(0, -3)}${CREATE_TRANSACTION_V2}`;
    return this.http.post<Order>(url, body).pipe(
      catchError((error) => {
        return throwError(error && error.error && error.error.error);
      }),
    );
  }

  payUpsells(
    order,
    upsells,
    type,
    totalTax,
    dataForTax,
  ) {
    const bodyForPayUpsells = {
      accId: order.accountId,
      orderType: type,
      totalTax,
      parent: order.id,
      orderSource: 'Funnel',
      upsells,
      dataForTax,
      paymentOptionId: order.paymentOptionId,
      orderTransactionFee: order.transactionFeeAmount,
      transactionFeeDocumentId: order.transactionFeeDocumentId,
      statsInfo: this.funnelProvider.getStats(),
    }

    if (order.stripeDetails) {
      const payUpsellsBodyFields = ['stripeDetails', 'paymentMethod', 'namedAgent', 'isApplePaySubscription', 'isGooglePaySubscription'];

      payUpsellsBodyFields.forEach(field => bodyForPayUpsells[field] = order[field]);
    }

    return this.http.post(`${API_URL}${PAY_WITHOUT_LOGIN}`, bodyForPayUpsells)
      .pipe(
        catchError((error) => {
          return throwError(error && error.error && error.error.error);
        }),
      );
  }

  payUpsellGoogleAndApplepay(order, upsells, type, token, paymentMethod, totalTax, dataForTax) {
    return this.http
      .post(`${API_URL}${PAY_WITHOUT_LOGIN}`, {
        accId: order.accountId,
        orderType: type,
        parent: order.id,
        orderSource: 'App',
        upsells,
        paymentMethod,
        namedAgent: GOOGLE_APPLE_PAYMENT_AGENT,
        totalTax,
        dataForTax,
        cardDetails: {
          cardId: token,
        },
        statsInfo: this.funnelProvider.getStats(),
      })
      .pipe(
        catchError((error) => {
          return throwError(error && error.error && error.error.error);
        }),
      );
  }

  setOrderGuest(body) {
    if (!body.orderSource) {
      body.orderSource = 'Cart';
    }
    return this.http.post(`${API_URL}${CREATE_WITHOUT_LOGIN}`, body).pipe(
      catchError((error) => {
        this.loggerProvider.logToServer({
          level: 'error',
          message: 'Error on setOrderGuest(): ',
          logObject: { error }
        } as LogData);
        return throwError(error && error.error && error.error.error);
      }),
    );
  }

  setOrder(body) {
    if (!body.orderSource) {
      body.orderSource = 'Cart';
    }
    return this.http.post(`${API_URL}${CREATE_WITH_LOGIN}`, body).pipe(
      catchError((error) => {
        this.loggerProvider.logToServer({
          level: 'error',
          message: 'Error on setOrder(): ',
          logObject: { error }
        } as LogData);
        return throwError(error && error.error && error.error.error);
      }),
    );
  }

  setUpsells(order, upsells, type, paymentMethod, totalTax) {
    return this.http
      .post(`${API_URL}${CREATE_WITH_LOGIN}`, {
        accId: order.accountId,
        orderType: type,
        parent: order.id,
        orderSource: 'Funnel',
        upsells,
        paymentMethod,
        namedAgent: paymentMethod,
        cardDetails: {},
        totalTax,
        statsInfo: this.funnelProvider.getStats(),
      })
      .pipe(
        catchError((error) => {
          return throwError(error && error.error && error.error.error);
        }),
      );
  }

  createTransaction(body) {
    return this.http.post(`${API_URL}${CREATE_TRANSACTION}`, body).pipe(
      catchError((error) => {
        return throwError(error && error.error && error.error.error);
      }),
    );
  }

  createPayPalPayment(body) {
    return this.http.post(`${API_URL}${CREATE_PAYPAL_PAYMENT}`, body).pipe(
      catchError((error) => {
        return throwError(error && error.error && error.error.error);
      }),
    );
  }

  executePayPalPayment(body) {
    body.statsInfo = this.funnelProvider.getStats();
    return this.http.post(`${API_URL}${EXECUTE_PAYPAL_PAYMENT}`, body).pipe(
      catchError((error) => {
        return throwError(error && error.error && error.error.error);
      }),
    );
  }

  createPayPalSubscription(body) {
    return this.http.post(`${API_URL}${CREATE_PAYPAL_SUBSCRIPTION}`, body).pipe(
      catchError((error) => {
        return throwError(error && error.error && error.error.error);
      }),
    );
  }

  executePayPalSubscription(body) {
    return this.http.post(`${API_URL}${EXECUTE_PAYPAL_SUBSCRIPTION}`, body).pipe(
      catchError((error) => {
        return throwError(error && error.error && error.error.error);
      }),
    );
  }

  cancelPayPalSubscription(body) {
    return this.http.post(`${API_URL}${CANCEL_PAYPAL_SUBSCRIPTION}`, body).pipe(
      catchError((error) => {
        return throwError(error && error.error && error.error.error);
      }),
    );
  }

  getExpressCheckoutDetails(body) {
    return this.http.post(`${API_URL}${GET_EXPRESS_CHECKOUT_DETAIL}`, body).pipe(
      catchError((error) => {
        return throwError(error && error.error);
      }),
    );
  }

  setExpressCheckout(body) {
    return this.http.post(`${API_URL}${SET_EXPRESS_CHECKOUT}`, body).pipe(
      catchError((error) => {
        return throwError(error && error.error);
      }),
    );
  }

  doExpressCheckout(body) {
    body.statsInfo = this.funnelProvider.getStats();
    return this.http.post(`${API_URL}${DO_EXPRESS_CHECKOUT}`, body).pipe(
      catchError((error) => {
        return throwError(error && error.error);
      }),
    );
  }

  createRecurringPayment(body) {
    return this.http.post(`${API_URL}${CREATE_RECURRING_PAYMENT}`, body).pipe(
      catchError((error) => {
        return throwError(error && error.error);
      }),
    );
  }

  payWithAmazon(body) {
    body.statsInfo = this.funnelProvider.getStats();
    return this.http.post(`${API_URL}${PAY_WITH_AMAZON}`, body).pipe(
      catchError((error) => {
        this.loggerProvider.logToServer({
          level: 'error',
          message: 'Error on payWithAmazon(): ',
          logObject: { error }
        } as LogData);
        return throwError(error && error.error && error.error.error);
      }),
    );
  }

  payWithAmazonSub(body) {
    body.statsInfo = this.funnelProvider.getStats();
    return this.http.post(`${API_URL}${PAY_WITH_AMAZONSUB}`, body).pipe(
      catchError((error) => {
        return throwError(error && error.error && error.error.error);
      }),
    );
  }

  getInfoAmazon(body) {
    body.statsInfo = this.funnelProvider.getStats();
    return this.http.post(`${API_URL}${GET_INFO_AMAZON}`, body).pipe(
      catchError((error) => {
        return throwError(error && error.error && error.error.error);
      }),
    );
  }

  getInfoAmazonSub(body) {
    body.statsInfo = this.funnelProvider.getStats();
    return this.http.post(`${API_URL}${GET_INFO_AMAZONSUB}`, body).pipe(
      catchError((error) => {
        return throwError(error && error.error && error.error.error);
      }),
    );
  }

  captureAmazon(body) {
    body.statsInfo = this.funnelProvider.getStats();
    return this.http.post(`${API_URL}${CAPTURE_AMAZON}`, body).pipe(
      catchError((error) => {
        return throwError(error && error.error && error.error.error);
      }),
    );
  }
}
