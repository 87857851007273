import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { LoggerBase } from '../logger/logger-base';

@Injectable({
  providedIn: 'root',
})
export class LoggingProvider extends LoggerBase {
  constructor(
    public http: HttpClient,
    public toastCtrl: ToastController
  ) {
    super(http, toastCtrl);
  }

  logEvent(message: string) {
    // TOUC-9812
    let xhr = new XMLHttpRequest();
    let asy = true;
    xhr.open('GET', '/logging?msg=' + encodeURIComponent(message) +
      '&url=' + encodeURIComponent(document.location.href) +
      '&funnel_conf_destinations=' + window['funnel_confs'].destinations.length +
      '&funnel_conf_funnels=' + Object.keys(window['funnel_confs'].funnels).length, asy);
    xhr.withCredentials = true;
    xhr.timeout = 5000;
    if (typeof window['getCookie'] === 'function') xhr.setRequestHeader('XSRF-TOKEN', window['getCookie']('XSRF-TOKEN')); // [TOUC-5682]
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 2) {
        console.log("[GH] logging complete", xhr);
      }
    }
    xhr.send();
  }

  logPaymentEvent(message: string) {

  }
}
