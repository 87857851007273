import { HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage';

import { GeneralProvider } from '../general/general';
import { UrlsProvider } from '../urls/urls';
import { TouchcrApiContentProvider } from '../touchcr-api-content/touchcr-api-content';
import { EventStreamerProvider } from '../event-stream/event-stream';
import { LoggerProvider } from '../logger/logger';

import { routes } from '../../app-routing.module';
import { pathNames } from '../../app-routing.module';

import { LogData } from '../../models/logData';

import { FIRST, LAST } from '../../constants/constants';

const API_URL = window['process_env'].API_URL;
const FUNNEL_CONFS = window['funnel_confs'] ? window['funnel_confs'].funnels : null;
const DESTINATIONS: any = window['funnel_confs'] ? window['funnel_confs'].destinations : null;

export const UNIQUE_IDENTIFIER = 'uid';
export const DESTINATION_ID = 'dsid';
export const CONTENT_ID = 'pcid';

let SESSION_ID: string;
let UID: string;
let SESSION_ID_GENERATED: boolean = false;
let DESTINATION_ID_VALUE: string;
let CONTENT_ID_VALUE: string = '';

export class FunnelSettingsBase {
  private readonly STATS: string = 'stats';

  constructor(
    public http: HttpClient,
    public generalProvider: GeneralProvider,
    public storage: Storage,
    public urls: UrlsProvider,
    public contentProvider: TouchcrApiContentProvider,
    public eventStreamer: EventStreamerProvider,
    public loggerProvider: LoggerProvider
  ) {}

  // Gets Funnel Setting from Offer Id when using affiliate links
  async getFunnelFromOfferById(funnelId: string = '') {
    const mainFunnel = this.getMainFunnelBySFID(funnelId);

    if (!mainFunnel || !mainFunnel.defaultFunnels) return;
    const { defaultFunnels } = mainFunnel;
    if (!defaultFunnels) return;
    const mDefaultFunnels = this.filterFunnelConfiguration(defaultFunnels);
    if (!mDefaultFunnels) return;
    const nextDefaultFunnel = mDefaultFunnels[0];
    if (!nextDefaultFunnel) return;
    const orderForm = mainFunnel.orderForm;
    const parType = nextDefaultFunnel.type;
    const parDefaultFunnel = nextDefaultFunnel;
    const parMainFunnelID = mainFunnel.sfid;
    const parUid = mainFunnel.uniqueIdentifier;

    const nextPage = await this.calculateNextPage(parDefaultFunnel, parDefaultFunnel.variants, parMainFunnelID);
    if (!nextPage) return;
    const { url, contentId, variantId } = nextPage;
    this.setContentIdToQuery(contentId);
    const component = await this.getComponent(url);
    if (!component) return;
    this.saveFunnelHistory(parMainFunnelID, parDefaultFunnel, url, variantId);
    let splitTestingId = null;
    mainFunnel.defaultFunnels.forEach((e) => {
      if (e.splitTesting && e.splitTesting.length > 0) {
        splitTestingId = e.splitTesting[0].sfid;
      }
    });
    const params = {
      component,
      orderForm,
      type: parType,
      defaultFunnel: parDefaultFunnel,
      mainFunnelID: parMainFunnelID,
      url,
      uid: parUid,
      splitTestingPageId: splitTestingId,
      eventType: 'Page View',
    };
    if (!params) return;
    this.sendStatsToAPI(params);
    if (!this.urls.getParameterFromUrl({ url: this.urls.getQueryParams(), parameter: UNIQUE_IDENTIFIER })) {
      this.setUID(params.uid);
    }
    if (params.defaultFunnel && params.defaultFunnel.step) this.setNextStep(params.defaultFunnel.step);
    return params;
  }

  async getNextPageByDestination(dstGenericUrl = '') {
    if (!dstGenericUrl) return;
    const result = this.nextStepDestination(dstGenericUrl);
    if (!result || !result.mainFunnel || !result.destinationId) {
      return;
    }
    const { mainFunnel, destinationId } = result;
    const firsStep = mainFunnel.defaultFunnels[0];
    const genericUrlOfFirstStep = firsStep.genericUrl;
    let newQuery = this.urls.getQueryParams();

    if (this.urls.getParameterFromUrl({ url: newQuery, parameter: UNIQUE_IDENTIFIER })) {
      newQuery = this.urls.removeParamFromQuery(newQuery, UNIQUE_IDENTIFIER);
    }
    if (this.urls.getParameterFromUrl({ url: newQuery, parameter: DESTINATION_ID })) {
      newQuery = this.urls.removeParamFromQuery(newQuery, DESTINATION_ID);
    }
    newQuery = this.urls.addParamToQuery(newQuery, UNIQUE_IDENTIFIER, mainFunnel.uniqueIdentifier);
    this.urls.setQueryParams(newQuery);
    this.setDestinationIdValue(destinationId);
    const params = await this.getNextPage(genericUrlOfFirstStep);
    if (!params) return;
    this.eventStreamer.streamEvent('appScreenView', {
      screenPath: `gu/${params.defaultFunnel.genericUrl}`,
      screenName: 'GenericUrlFunnel',
      customTitle: 'GenericUrlFunnel',
      fullUrl: window.location.href,
    });

    return params;
  }

  nextStepDestination(genericUrl) {
    if (!DESTINATIONS || !genericUrl) return;

    const destination = DESTINATIONS.find(e => e.genericUrl === genericUrl);
    if (!destination) return;
    let mainFunnel;
    const defaultMainFunnel = this.getMainFunnelBySFID(destination.defaultFunnel);

    if (!destination.splitTestEnabled || (destination.splitTestEnabled && destination.percentage === 100)) {
      mainFunnel = defaultMainFunnel;
      if (!mainFunnel) return;
    } else {
      const mainFunnels = this.getMainFunnelsByDestinationId(destination.sfid);
      if (!mainFunnels || !mainFunnels.length || mainFunnels.length === 0) return;
      const mDefaultMainFunnel = JSON.parse(JSON.stringify(defaultMainFunnel));
      mDefaultMainFunnel.percentage = destination.percentage;
      mainFunnels.push(mDefaultMainFunnel);
      mainFunnel = this.getWithProbability(mainFunnels);
    }
    const splitTestingId = (
      destination.splitTesting &&
      destination.splitTesting.length &&
      destination.splitTesting.length > 0) ? destination.splitTesting[0].sfid : null;
    this.sendStatsToAPIDest({
      funnelSTFId: mainFunnel.sfid,
      destinationId: destination.sfid,
      splitTestingId,
      eventType: 'Destination',
    });
    let destinationId = '';
    if (destination) {
      destinationId = destination.sfid;
    }
    return {mainFunnel, destinationId};
  }

  async sendStatsToAPIDest({
                         funnelSTFId = null,
                         destinationId = null,
                         splitTestingId = null,
                         eventType = null,
                       } = {}) {
    const sessionIdGenerated = this.getSessionIDGenerated();
    if (sessionIdGenerated === true) {
      this.setSessionIDGenerated(false);
      this.sendStatsToAPIDest({
        funnelSTFId,
        destinationId,
        splitTestingId,
        eventType: 'New Session',
      });
    }
    if (!funnelSTFId || !destinationId) return;
    const tmp = {
      funnelSTFId,
      sessionId: this.getSessionId(),
      destinationId,
      splitTestingId,
      eventType,
    };
    return this.http.post(`${API_URL}funnel/stats/save`, tmp).toPromise()
      .catch((error) => {
        this.loggerProvider.logToServer({
          level: 'error',
          message: 'Error on saving funnel statistics: ',
          logObject: { error }
        } as LogData);
      });
  }

  async getNextPage(genericUrl = '', button: boolean = false, type: string = '', isPageFail = false) {
    if (button || type || isPageFail) {
      return await this.getNextPageUpsell(button, type, isPageFail);
    } else {
      return await this.getNextPageV2(genericUrl);
    }
  }

  filterFunnelConfiguration(defaultFunnels = []) {
    if (defaultFunnels.length === 0) return;
    const mDefaultFunnels = JSON.parse(JSON.stringify(defaultFunnels));
    const isMobile = this.generalProvider.isMobile();
    const currentUrl = this.getCurrentUrl();
    const platform = isMobile ? 'Mobile only' : 'Desktop only';
    return mDefaultFunnels
      .map((e) => {
        if (e.variants) {
          e.variants = e.variants
            .map((e1) =>
              !(
                !e1.splitTestEnabled &&
                e1.visibility !== 'All' &&
                e1.visibility !== platform &&
                e1.url !== currentUrl
              )
                ? e1
                : false,
            )
            .filter((e1) => e1);
        }
        return e;
      })
      .filter((e) => e.step && e.variants && e.variants.length !== 0)
      .sort((a, b) => a.step - b.step);
  }

  async getNextPageV2(genericUrl = '') {
    let params: any = '';
    let orderForm: string;
    let parDefaultFunnel: any = '';
    let parType: string;
    let parMainFunnelID = '';
    let parUid: string;
    let currentmDefaultFunnels: any = '';
    let currentDefaultFunnel: any = '';
    let currentDefaultFunnelId = '';
    let mainFunnel: any = '';
    if (!FUNNEL_CONFS) return;
    if (genericUrl) {
      mainFunnel = this.getMainFunnelByGenericUrl(genericUrl);
      if (!mainFunnel || !mainFunnel.defaultFunnels) return;

      const { defaultFunnels } = mainFunnel;
      if (!defaultFunnels) return;
      const mDefaultFunnels = this.filterFunnelConfiguration(defaultFunnels);
      if (!mDefaultFunnels) return;

      let defaultFunnel = mDefaultFunnels.find((e) => e.genericUrl === genericUrl);
      if (!defaultFunnel) [defaultFunnel] = mDefaultFunnels;
      const { variants } = defaultFunnel;
      if (!variants) return;
      orderForm = mainFunnel.orderForm;
      parType = defaultFunnel.type;
      parDefaultFunnel = defaultFunnel;
      parMainFunnelID = mainFunnel.sfid;
      parUid = mainFunnel.uniqueIdentifier;
    } else {
      let isSearchCurrentUrl = false;
      mainFunnel = this.getMainFunnelByUID();
      if (!mainFunnel || !mainFunnel.defaultFunnels) isSearchCurrentUrl = true;
      if (!isSearchCurrentUrl) {
        let { defaultFunnels } = mainFunnel;
        if (!defaultFunnels) return;
        defaultFunnels = this.filterFunnelConfiguration(defaultFunnels);
        if (!defaultFunnels) return;
        currentmDefaultFunnels = defaultFunnels;
        if (this.urls.getParameterFromUrl({ url: this.urls.getQueryParams(), parameter: 'step' })) {
          const currentStep = Number(
            this.urls.getParameterFromUrl({ url: this.urls.getQueryParams(), parameter: 'step' }),
          );
          currentDefaultFunnelId = '';
          let numUrlInFunnel = 0;
          currentDefaultFunnel = defaultFunnels.find((e, i) => {
            currentDefaultFunnelId = i;
            e.variants.find((e1, i1) => {
              if (e1.url === this.getCurrentUrl()) {
                numUrlInFunnel = e.step;
              }
            });
            if (numUrlInFunnel > 0 && numUrlInFunnel < currentStep) {
              return true;
            }
            return e.step === currentStep;
          });
        }
        if (!currentDefaultFunnel || !currentDefaultFunnel.step) isSearchCurrentUrl = true;
      }

      if (isSearchCurrentUrl) {
        const currentUrl = this.getCurrentUrl();
        if (!currentUrl) return;
        mainFunnel = this.getMainFunnelByCurrentUrl();
        if (!mainFunnel || !mainFunnel.defaultFunnels) return;
        const { defaultFunnels } = mainFunnel;
        if (!defaultFunnels) return;
        const mDefaultFunnels = this.filterFunnelConfiguration(defaultFunnels);
        if (!mDefaultFunnels) return;
        currentmDefaultFunnels = mDefaultFunnels;
        currentDefaultFunnelId = '';
        currentDefaultFunnel = mDefaultFunnels.find((e, i) => {
          currentDefaultFunnelId = i;
          return (
            e.url === currentUrl || (e.variants && e.variants.find((e1) => e1.url === currentUrl))
          );
        });
        if (!currentDefaultFunnel || !currentDefaultFunnel.step) return;
        const currentVariant =
          currentDefaultFunnel.url === currentUrl
            ? currentDefaultFunnel
            : currentDefaultFunnel.variants &&
              currentDefaultFunnel.variants.find((e) => e.url === currentUrl);
        if (!currentVariant) return;
      }

      const nextDefaultFunnel = currentmDefaultFunnels[currentDefaultFunnelId + 1];
      if (!nextDefaultFunnel) return;
      orderForm = nextDefaultFunnel.upsell || nextDefaultFunnel.downsell || mainFunnel.orderForm;
      parType = nextDefaultFunnel.type;
      parDefaultFunnel = nextDefaultFunnel;
      parMainFunnelID = mainFunnel.sfid;
      parUid = mainFunnel.uniqueIdentifier;
    }

    const nextPage = await this.calculateNextPage(
      parDefaultFunnel,
      parDefaultFunnel.variants,
      parMainFunnelID,
    );
    if (!nextPage) return;
    const { url, contentId, variantId } = nextPage;
    this.setContentIdToQuery(contentId);
    const component = await this.getComponent(url);
    if (!component) return;
    this.saveFunnelHistory(parMainFunnelID, parDefaultFunnel, url, variantId);
    let splitTestingId = null;
    mainFunnel.defaultFunnels.forEach((e) => {
      if (e.splitTesting && e.splitTesting.length > 0) {
        splitTestingId = e.splitTesting[0].sfid;
      }
    });
    params = {
      component,
      orderForm,
      type: parType,
      defaultFunnel: parDefaultFunnel,
      mainFunnelID: parMainFunnelID,
      url,
      uid: parUid,
      splitTestingPageId: splitTestingId,
      eventType: 'Page View',
    };
    if (!params) return;
    this.sendStatsToAPI(params);
    if (
      !this.urls.getParameterFromUrl({ url: this.urls.getQueryParams(), parameter: UNIQUE_IDENTIFIER })
    ) {
      this.setUID(params.uid);
    }
    if (params.defaultFunnel && params.defaultFunnel.step)
      this.setNextStep(params.defaultFunnel.step);
    return params;
  }

  getCurrentUrl() {
    return window.location.pathname.split('/')[1];
  }

  async saveUserStats(stats: any = {}) {
    if (!stats) return;
    stats.deviceInfo = await this.generalProvider.getDeviceInfo();
    await this.storage.set(this.STATS, stats);
  }

  getUserStats() {
    return this.storage.get(this.STATS);
  }

  async saveFunnelHistory(
    mainFunnelId = '',
    defaultFunnel: any = '',
    url: any = '',
    variantId = '',
  ) {
    if (!mainFunnelId || !defaultFunnel || !defaultFunnel.genericUrl || !url) return;
    let stats = await this.getUserStats();
    if (!stats) stats = {};
    if (!stats.funnels) stats.funnels = {};
    if (!stats.funnels[mainFunnelId]) stats.funnels[mainFunnelId] = [];
    const spId =
      defaultFunnel.splitTesting[0] && defaultFunnel.splitTesting[0].sfid
        ? defaultFunnel.splitTesting[0].sfid
        : null;
    const obj = {
      step: defaultFunnel.step,
      genericUrl: defaultFunnel.genericUrl,
      url,
      spId,
      variantId,
    };
    const isExist = stats.funnels[mainFunnelId].find(
      (e) => e.genericUrl === defaultFunnel.genericUrl,
    );
    if (!isExist) {
      stats.funnels[mainFunnelId].push(obj);
    } else {
      stats.funnels[mainFunnelId] = stats.funnels[mainFunnelId].map((e) => {
        if (e.genericUrl === defaultFunnel.genericUrl) {
          return obj;
        }
        return e;
      });
    }
    stats.funnels[mainFunnelId].sort((a, b) => a.step - b.step);
    await this.saveUserStats(stats);
  }

  getComponent(url: any = '') {
    if (!url) return;
    const component: any = routes.find((e) => e.path === url);
    // const component = [];
    if (!component) return;
    const pathName: any = pathNames.find((e) => e.path === url);
    if (pathName) {
      component['name'] = pathName.name;
    } else {
      component['name'] = component.path;
    }
    return component;
  }

  async calculateNextPage(defaultFunnel: any = '', variants: any = '', mainFunnelId = '') {
    if (!defaultFunnel.splitTestEnabled) {
      const defVariant = variants.find((e) => e.default);
      if (!defVariant) return;
      return {
        url: defVariant.url,
        contentId: defVariant.pageContent,
        variantId: defVariant.sfid,
      };
    }
    const isPageViewed = await this.checkIfPageViewed(defaultFunnel, mainFunnelId);
    if (isPageViewed) {
      const { url, variantId } = isPageViewed;
      const currentVariant = variants.find((e) => e.sfid === variantId);
      if (currentVariant) {
        return {
          url,
          contentId: currentVariant.pageContent,
          variantId: currentVariant.sfid,
        };
      }
    }
    const calcObj = this.getWithProbability(variants);
    if (!calcObj)
      return {
        url: null,
        contentId: null,
        variantId: null,
      };
    return {
      url: calcObj.url,
      contentId: calcObj.pageContent,
      variantId: calcObj.sfid,
    };
  }

  async checkIfPageViewed(defaultFunnel: any = '', mainFunnelId: any = '') {
    if (!defaultFunnel || !mainFunnelId || !defaultFunnel.variants) return;
    const stats = await this.getUserStats();
    if (!stats || !stats.funnels) return;
    const mainFunnel = stats.funnels[mainFunnelId];
    if (!mainFunnel || !mainFunnel.length || mainFunnel.length === 0) return;
    const savedFunnel = mainFunnel.find((e) => e.genericUrl === defaultFunnel.genericUrl);
    if (!savedFunnel) return;
    if (defaultFunnel.splitTestEnabled) {
      if (defaultFunnel.splitTesting[0] && defaultFunnel.splitTesting[0].sfid !== savedFunnel.spId) {
        return;
      }
    }
    const component = this.getComponent(savedFunnel.url);
    if (!component) return;
    const ifExistInConfiguration = defaultFunnel.variants.find((e) => e.url === savedFunnel.url);
    if (!ifExistInConfiguration) return;
    return {
      url: savedFunnel.url,
      variantId: savedFunnel.variantId,
    };
  }

  shuffleArray(arr) {
    const newArr = [...arr];
    for (let i = newArr.length - 1; i > 0; i--) {
      const rand = Math.floor(Math.random() * (i + 1));
      [newArr[i], newArr[rand]] = [newArr[rand], newArr[i]];
    }
    return newArr;
  }

  getWithProbability(array = []) {
    if (array.length === 0) return;
    if (array.length === 1) return array[0];
    let tmpArr = [];
    array.forEach((e) => {
      if (e.percentage) tmpArr.push(...Array(Math.round(+e.percentage)).fill(e));
    });
    tmpArr = this.shuffleArray(tmpArr);
    return tmpArr[Math.floor(Math.random() * (tmpArr.length - 1))];
  }

  async getOrderFormByCurrentUrl() {
    if (!FUNNEL_CONFS) return;
    const currentUrl = this.getCurrentUrl();
    if (!currentUrl) return;
    const mainFunnel = this.getMainFunnelByCurrentUrl();
    if (!mainFunnel || !mainFunnel.orderForm) return;
    return mainFunnel.orderForm;
  }

  async sendVideoStats(videoPercentage: number = null) {
    return;
  }

  async sendCustomStats(customEventType: any, customPayLoad: any) {
    const customPayLoad1 = {};
    const customPayLoad2 = {};

    if (customPayLoad) {
      const keysCP = Object.keys(customPayLoad);

      keysCP.forEach((e) => {
        if (Object.keys(customPayLoad1).length < keysCP.length / 2) {
          customPayLoad1[e] = customPayLoad[e];
        } else {
          customPayLoad2[e] = customPayLoad[e];
        }
      });
    }

    const funnelInfo = this.getCurrentFunnelInfo();
    if (!funnelInfo) return;
    const { defaultFunnel, mainFunnel, currentUrl, splitTestingId } = funnelInfo;
    if (!defaultFunnel || !mainFunnel || !currentUrl) return;

    this.sendStatsToAPI({
      defaultFunnel,
      mainFunnelID: mainFunnel.sfid,
      url: currentUrl,
      splitTestingPageId: splitTestingId,
      customPayLoad1: JSON.stringify(customPayLoad1) || null,
      customPayLoad2: JSON.stringify(customPayLoad2) || null,
      eventType: customEventType || null,
    });
  }

  async sendStatsToAPI({
    component = null,
    orderForm = null,
    type = null,
    defaultFunnel = null,
    mainFunnelID = null,
    url = null,
    uid = null,
    splitTestingFunnelId = null,
    splitTestingPageId = null,
    videoPercentage = null,
    customPayLoad1 = null,
    customPayLoad2 = null,
    eventType = null,
  } = {}) {
    const sessionIdGenerated = this.getSessionIDGenerated();
    if (sessionIdGenerated === true) {
      this.setSessionIDGenerated(false);
      this.sendStatsToAPI({
        component,
        orderForm,
        type,
        defaultFunnel,
        mainFunnelID,
        url,
        uid,
        splitTestingFunnelId,
        splitTestingPageId,
        videoPercentage,
        customPayLoad1,
        customPayLoad2,
        eventType: 'New Session',
      });
    }
    if (!defaultFunnel || !mainFunnelID || !url) return;
    let funnelId = '';
    const { variants } = defaultFunnel;
    if (variants) {
      const currentVariant = variants.find((e) => e.url === url);
      if (currentVariant) funnelId = currentVariant.sfid;
    }
    const destinationId = this.getDestinationIdValue() ? this.getDestinationIdValue() : null;
    const stFunnelId = this.getSPFId();
    const tmp = {
      funnelSTPId: funnelId,
      url,
      mainFunnelId: mainFunnelID,
      sessionId: SESSION_ID,
      splitTestingFunnelId: stFunnelId,
      splitTestingPageId,
      videoPercentage,
      customPayLoad1,
      customPayLoad2,
      eventType,
      destinationId,
    };
    return this.http
      .post(`${API_URL}funnel/stats/save`, tmp)
      .toPromise()
      .catch((error) => {
        this.loggerProvider.logToServer({
          level: 'error',
          message: 'Error on saving funnel statistics: ',
          logObject: { error }
        } as LogData);
      });
  }

  async getNextPageUpsell(button: boolean = false, type: string = '', isPageFail = false) {
    if (!FUNNEL_CONFS) return;

    let currentDefaultFunnelId = '';
    let currentDefaultFunnel: any;
    let isSearchCurrentUrl = false;
    let mainFunnel = this.getMainFunnelByUID();
    let defaultFunnels: any;
    if (!mainFunnel || !mainFunnel.defaultFunnels) isSearchCurrentUrl = true;
    else {
      defaultFunnels = mainFunnel.defaultFunnels;
      if (!defaultFunnels) return;
      defaultFunnels = this.filterFunnelConfiguration(defaultFunnels);
      if (this.urls.getParameterFromUrl({ url: this.urls.getQueryParams(), parameter: 'step' })) {
        if (!defaultFunnels) return;
        const currentStep = Number(
          this.urls.getParameterFromUrl({ url: this.urls.getQueryParams(), parameter: 'step' }),
        );
        currentDefaultFunnel = defaultFunnels.find((e, i) => {
          currentDefaultFunnelId = i;
          return e.step === currentStep;
        });
      }
      if (!currentDefaultFunnel || !currentDefaultFunnel.step) isSearchCurrentUrl = true;
    }
    if (isSearchCurrentUrl) {
      const currentUrl = this.getCurrentUrl();
      if (!currentUrl) return;
      mainFunnel = this.getMainFunnelByCurrentUrl();
      if (!mainFunnel || !mainFunnel.defaultFunnels) return;
      defaultFunnels = mainFunnel.defaultFunnels;
      if (!defaultFunnels) return;
      defaultFunnels = this.filterFunnelConfiguration(defaultFunnels);
      if (!defaultFunnels) return;
      currentDefaultFunnelId = '';
      currentDefaultFunnel = defaultFunnels.find((e, i) => {
        currentDefaultFunnelId = i;
        return (
          e.url === currentUrl || (e.variants && e.variants.find((e1) => e1.url === currentUrl))
        );
      });
    }

    let url = '';
    let contentId = '';
    let variantId = '';
    let nextDefaultFunnel: any = '';
    if (!currentDefaultFunnel || !currentDefaultFunnel.step) return;
    if (isPageFail) return await this.getPageFail(defaultFunnels);
    if (currentDefaultFunnel.overrideYes && button) {
      const arr = await this.getFunnelBySfid(defaultFunnels, currentDefaultFunnel.overrideYes);
      if (arr && arr.length > 0) {
        [nextDefaultFunnel] = arr;
        url = arr[1].url;
        contentId = arr[1].pageContent;
        variantId = arr[1].sfid;
      }
    } else if (currentDefaultFunnel.overrideNo && !button) {
      const arr = await this.getFunnelBySfid(defaultFunnels, currentDefaultFunnel.overrideNo);
      if (arr && arr.length > 0) {
        [nextDefaultFunnel] = arr;
        url = arr[1].url;
        contentId = arr[1].pageContent;
        variantId = arr[1].sfid;
      }
    }
    if (!url || !nextDefaultFunnel) {
      const arr = defaultFunnels.slice(currentDefaultFunnelId + 1);
      nextDefaultFunnel =
        button || type === 'Downsell'
          ? await this.getNextUpsellFunnel(arr)
          : await this.getNextDownsellFunnel(arr);
      if (!nextDefaultFunnel) {
        nextDefaultFunnel = defaultFunnels
          .slice(currentDefaultFunnelId)
          .find(
            (e) =>
              e.type && e.type.toLowerCase() !== 'upsell' && e.type.toLowerCase() !== 'downsell',
          );
      }
      if (!nextDefaultFunnel || !nextDefaultFunnel.variants) return;
      const nextPage = await this.calculateNextPage(
        nextDefaultFunnel,
        nextDefaultFunnel.variants,
        mainFunnel.sfid,
      );
      if (nextPage) {
        ({ url, contentId, variantId } = nextPage);
      }
    }
    const component = await this.getComponent(url);
    if (!component) return;
    this.saveFunnelHistory(mainFunnel.sfid, nextDefaultFunnel, url, variantId);
    let splitTestingId = null;
    mainFunnel.defaultFunnels.forEach((e) => {
      if (e.splitTesting && e.splitTesting.length > 0) {
        splitTestingId = e.splitTesting[0].sfid;
      }
    });
    const params = {
      component,
      orderForm: nextDefaultFunnel.upsell || nextDefaultFunnel.downsell || mainFunnel.orderForm,
      type: nextDefaultFunnel.type,
      defaultFunnel: nextDefaultFunnel,
      mainFunnelID: mainFunnel.sfid,
      url,
      splitTestingPageId: splitTestingId,
      eventType: 'Page View',
    };
    this.sendStatsToAPI(params);
    this.setContentIdToQuery(contentId);
    if (params.defaultFunnel && params.defaultFunnel.step)
      this.setNextStep(params.defaultFunnel.step);
    return params;
  }

  async getNextUpsellFunnel(defaultFunnels = []) {
    if (defaultFunnels.length === 0) return;
    const upsellFunnel = defaultFunnels.find((e) => e.type && e.type.toLowerCase() === 'upsell');
    if (!upsellFunnel) return;
    return upsellFunnel;
  }

  async getNextDownsellFunnel(defaultFunnels = []) {
    if (defaultFunnels.length === 0) return;
    const downsellFunnel = defaultFunnels[0];
    if (
      !downsellFunnel ||
      (downsellFunnel.type && downsellFunnel.type.toLowerCase() !== 'downsell')
    ) {
      return await this.getNextUpsellFunnel(defaultFunnels);
    }
    return downsellFunnel;
  }

  async getPageFailFunnel(defaultFunnels = []) {
    if (defaultFunnels.length === 0) return;
    const currentUrl = this.getCurrentUrl();
    const currentDefaultFunnel = defaultFunnels.find(
      (e) => e && e.variants && e.variants.find((e1) => e1.url === currentUrl),
    );
    if (!currentDefaultFunnel) return;
    if (currentDefaultFunnel.nextPageFail) {
      const nextPageFailFunnel = defaultFunnels.find(
        (e) => e.sfid === currentDefaultFunnel.nextPageFail,
      );
      if (nextPageFailFunnel) return nextPageFailFunnel;
    }
    const lastFunnel = defaultFunnels[defaultFunnels.length - 1];
    if (!lastFunnel) return;
    return lastFunnel;
  }

  async getPageFail(defaultFunnels = []) {
    if (defaultFunnels.length === 0) return;
    const pageFailFunnel = await this.getPageFailFunnel(defaultFunnels);
    if (!pageFailFunnel || !pageFailFunnel.variants) return;
    const { variants } = pageFailFunnel;
    const variant = pageFailFunnel.splitTestEnabled
      ? this.getWithProbability(variants)
      : variants.find((e) => e.default);
    if (!variant) return;
    const component = this.getComponent(variant.url);
    if (!component) return;
    if (pageFailFunnel.step)
      this.setNextStep(pageFailFunnel.step);
    return {
      component,
    };
  }

  async getFunnelBySfid(funnels = [], sfid = '') {
    if (funnels.length === 0 || !sfid) return;
    const defaultFunnel = funnels.find(
      (e) => e.variants && e.variants.find((e1) => e1.sfid === sfid),
    );
    if (!defaultFunnel) return;
    const variantFunnel = defaultFunnel.variants.find((e) => e.sfid === sfid);
    if (!variantFunnel) return;
    return [defaultFunnel, variantFunnel];
  }

  getSessionId() {
    return SESSION_ID;
  }

  getDestinationId() {
    const destinationid = this.urls.getParameterFromUrl({
      url: this.urls.getQueryParams(),
      parameter: DESTINATION_ID,
    });
    if (destinationid) {
      return destinationid;
    } else {
      return null;
    }
  }

  getSessionIDGenerated() {
    return SESSION_ID_GENERATED;
  }

  setSessionIDGenerated(value: boolean = false) {
    if (typeof value !== 'boolean') {
      return;
    }
    SESSION_ID_GENERATED = value;
  }

  setSessionId({ sessionId = '', isCreated = false }) {
    SESSION_ID = sessionId;
    this.setSessionIDGenerated(isCreated);
    let newQueryParam = this.urls.getQueryParams();
    newQueryParam = this.urls.addParamToQuery(newQueryParam, 'sessionid', sessionId);
    this.urls.changeState(newQueryParam);
  }

  setNextStep(nextStep) {
    let newQueryParam = this.urls.getQueryParams();
    if (this.urls.getParameterFromUrl({ url: newQueryParam, parameter: 'step' })) {
      newQueryParam = this.urls.changeParamInQuery(newQueryParam, 'step', nextStep);
    } else newQueryParam = this.urls.addParamToQuery(newQueryParam, 'step', nextStep);
    this.urls.setQueryParams(newQueryParam);
  }

  setUID(uid) {
    UID = uid;
    let newQueryParam = this.urls.getQueryParams();
    newQueryParam = this.urls.addParamToQuery(newQueryParam, 'uid', UID);
    this.urls.setQueryParams(newQueryParam);
  }

  getDestinationIdValue() {
    return DESTINATION_ID_VALUE;
  }

  setDestinationIdValue(destinationId: string = '') {
    if (!destinationId) return;
    DESTINATION_ID_VALUE = destinationId;
    let newQueryParam = this.urls.getQueryParams();
    newQueryParam = this.urls.addParamToQuery(newQueryParam, DESTINATION_ID, destinationId);
    this.urls.setQueryParams(newQueryParam);
  }

  async getBumpOffers() {
    const currentUrl = this.getCurrentUrl();
    if (!currentUrl || !FUNNEL_CONFS) return [];
    const mainFunnel = this.getMainFunnelByCurrentUrl();
    if (mainFunnel && mainFunnel.bumpOffers && mainFunnel.bumpOffers.length) return mainFunnel.bumpOffers;
    return [];
  }

  getMainFunnelByGenericUrl(genericUrl) {
    if (!genericUrl) return;
    return Object.keys(FUNNEL_CONFS)
      .map((key) => {
        return FUNNEL_CONFS[key];
      })
      .find((e) => e.defaultFunnels && e.defaultFunnels.find((el) => el.genericUrl === genericUrl));
  }

  getMainFunnelByCurrentUrl() {
    if (!FUNNEL_CONFS) return;
    const currentUrl = this.getCurrentUrl();
    const mainFunnel = this.getMainFunnelByUID();
    if (mainFunnel) return mainFunnel;
    return Object.keys(FUNNEL_CONFS)
      .map((key) => {
        return FUNNEL_CONFS[key];
      })
      .find(
        (e) =>
          e.defaultFunnels &&
          e.defaultFunnels.find(
            (e1) =>
              e1.url === currentUrl ||
              (e1.variants && e1.variants.find((e2) => e2.url === currentUrl)),
          ),
      );
  }

  isUIDExist() {
    return this.urls.getQueryParams().indexOf(UNIQUE_IDENTIFIER) !== -1;
  }

  isDSIDExist() {
    return this.urls.getQueryParams().indexOf(DESTINATION_ID) !== -1;
  }

  getSPFId() {
    const destinationId = this.getDestinationIdValue();
    const destinationSplit = DESTINATIONS.find((e) => {
      return e.sfid === destinationId && e.splitTestEnabled;
    });
    if (
      destinationSplit &&
      destinationSplit.splitTesting &&
      destinationSplit.splitTesting[0] &&
      destinationSplit.splitTesting[0].sfid
    ) {
      return destinationSplit.splitTesting[0].sfid;
    }
    return null;
  }

  getSPPId() {
    if (!FUNNEL_CONFS) return;
    const currentUrl = this.getCurrentUrl();
    if (!currentUrl) return;
    const mainFunnel = this.getMainFunnelByCurrentUrl();
    if (!mainFunnel || !mainFunnel.defaultFunnels) return;
    const { defaultFunnels } = mainFunnel;
    if (!defaultFunnels) return;
    let splitTestingId = '';
    mainFunnel.defaultFunnels.forEach((e) => {
      if (e.splitTesting && e.splitTesting.length > 0) {
        splitTestingId = e.splitTesting[0].sfid;
      }
    });
    return splitTestingId;
  }

  getMainFunnelByUID() {
    if (this.isUIDExist()) {
      const uniqueIdentifier = this.urls.getParameterFromUrl({
        url: this.urls.getQueryParams(),
        parameter: UNIQUE_IDENTIFIER,
      });
      if (uniqueIdentifier) {
        const mainFunnel = Object.keys(FUNNEL_CONFS)
          .map((key) => {
            return FUNNEL_CONFS[key];
          })
          .find((e) => e.uniqueIdentifier === uniqueIdentifier);
        if (mainFunnel) return mainFunnel;
      }
    }
  }

  getOrderFormByGenericURL(genericUrl: string) {
    if (!genericUrl) return;
    const mainFunnel = this.getMainFunnelByGenericUrl(genericUrl);
    if (!mainFunnel) return;
    if (mainFunnel.orderForm) return mainFunnel.orderForm;
  }

  getMainFunnelBySFID(sfid: string = '') {
    if (!sfid || !FUNNEL_CONFS) return;
    return FUNNEL_CONFS[sfid];
  }

  getMainFunnelsByDestinationId(sfid: string = '') {
    if (!sfid || !FUNNEL_CONFS) return;

    const mainFunnels = [];
    Object.keys(FUNNEL_CONFS)
      .map((key) => {
        return FUNNEL_CONFS[key];
      })
      .forEach((e) => {
        if (e.destinationId === sfid) mainFunnels.push(e);
      });

    return mainFunnels;
  }

  getUpsellByCurrentUrl() {
    const currentUrl = this.getCurrentUrl();
    const mainFunnel = this.getMainFunnelByCurrentUrl();
    if (!mainFunnel || !mainFunnel.defaultFunnels) return;
    const currentDefaultFunnel = mainFunnel.defaultFunnels.find((e) =>
      e.variants.find((el) => el.url === currentUrl),
    );
    if (!currentDefaultFunnel || !currentDefaultFunnel.upsell) return;
    return [currentDefaultFunnel.upsell];
  }

  getCurrentFunnelInfo() {
    if (!FUNNEL_CONFS) return;
    const currentUrl = this.getCurrentUrl();
    if (!currentUrl) return;
    const mainFunnel = this.getMainFunnelByCurrentUrl();
    if (!mainFunnel || !mainFunnel.defaultFunnels) return;
    const { defaultFunnels } = mainFunnel;
    if (!defaultFunnels) return;
    const mDefaultFunnels = this.filterFunnelConfiguration(defaultFunnels);
    if (!mDefaultFunnels) return;
    const currentStep = Number(
      this.urls.getParameterFromUrl({ url: this.urls.getQueryParams(), parameter: 'step' }),
    );
    const defaultFunnel = mDefaultFunnels.find(
      (e) => (
          e.url === currentUrl
          || (e.variants && e.variants.find((e1) => e1.url === currentUrl))
        ) && (
          currentStep ? e.step === currentStep : true
        )
    );
    if (!defaultFunnel) return;
    let splitTestingId = null;
    mainFunnel.defaultFunnels.forEach((e) => {
      if (e.splitTesting && e.splitTesting.length > 0) {
        splitTestingId = e.splitTesting[0].sfid;
      }
    });
    return {
      defaultFunnel,
      mainFunnel,
      currentUrl,
      splitTestingId,
    };
  }

  getStats() {
    const funnelInfo = this.getCurrentFunnelInfo();
    if (!funnelInfo) return;
    const { defaultFunnel, mainFunnel, currentUrl } = funnelInfo;
    if (!defaultFunnel || !mainFunnel || !currentUrl) return;
    let funnelSTPId = '';
    const { variants } = defaultFunnel;
    if (variants) {
      const currentVariant = variants.find((e) => e.url === currentUrl);
      if (currentVariant) funnelSTPId = currentVariant.sfid;
    }
    return {
      funnelSTPId,
      url: this.getCurrentUrl(),
      mainFunnelId: mainFunnel.sfid,
      sessionId: this.getSessionId(),
      splitTestingFunnelId: this.getSPFId(),
      splitTestingPageId: this.getSPPId(),
      destinationId: this.getDestinationId(),
    };
  }

  getContentId() {
    return CONTENT_ID_VALUE;
  }

  setContentId(id = '') {
    CONTENT_ID_VALUE = id;
  }

  async setContentIdToQuery(id = '') {
    let newQueryParams = this.urls.removeParamFromQuery(this.urls.getQueryParams(), CONTENT_ID);
    if (id) {
      newQueryParams = this.urls.addParamToQuery(newQueryParams, CONTENT_ID, id);
    }
    this.setContentId(id);
    await this.urls.setQueryParams(newQueryParams);
  }

  async getAndApplyContentPage(context) {
    const contentPage = await this.getContentPage();
    this.parseContentPage(contentPage, context);
  }

  async getContentPage() {
    const contentId = this.getContentId();
    if (contentId) {
      return await this.contentProvider.getPageContentBySFId(contentId).toPromise();
    }
  }

  parseContentPage(contentPage = {}, context = {}) {
    if (!contentPage || !context) return;
    Object.keys(contentPage).forEach((e) => {
      context[e] = contentPage[e];
    });
  }

  // Method for get page from current funnel settings by chosen step
  // Method get step in format 'first', 'last' or step number as integer.
  async getPageByStep(step: any = FIRST) {
    let params: any = '';
    let orderForm: string;
    let parDefaultFunnel: any = '';
    let parType: string;
    let parMainFunnelID = '';
    let parUid: string;
    let mainFunnel: any = '';
    if (!FUNNEL_CONFS) return;
    let isSearchCurrentUrl = false;
    let defaultFunnels;
    let mDefaultFunnels;
    mainFunnel = this.getMainFunnelByUID();
    if (!mainFunnel || !mainFunnel.defaultFunnels) isSearchCurrentUrl = true;
    if (!isSearchCurrentUrl) {
      defaultFunnels = mainFunnel.defaultFunnels;
      if (!defaultFunnels) return;
      mDefaultFunnels = this.filterFunnelConfiguration(defaultFunnels);
      if (!mDefaultFunnels) return;
    }

    if (isSearchCurrentUrl) {
      const currentUrl = this.getCurrentUrl();
      if (!currentUrl) return;
      mainFunnel = this.getMainFunnelByCurrentUrl();
      if (!mainFunnel || !mainFunnel.defaultFunnels) return;
      defaultFunnels  = mainFunnel.defaultFunnels;
      if (!defaultFunnels) return;
      mDefaultFunnels = this.filterFunnelConfiguration(defaultFunnels);
      if (!mDefaultFunnels) return;
    }

    if (step === FIRST) {
      parDefaultFunnel = mDefaultFunnels[0];
    } else if (step === LAST) {
      parDefaultFunnel = mDefaultFunnels[mDefaultFunnels.length - 1];
    } else {
      const filtredDefaultFunnels = mDefaultFunnels.filter(e => e.step === step);
      parDefaultFunnel = filtredDefaultFunnels ? filtredDefaultFunnels[0] : '';
    }

    if (!parDefaultFunnel) return;

    orderForm = parDefaultFunnel.upsell || parDefaultFunnel.downsell || mainFunnel.orderForm;
    parType = parDefaultFunnel.type;
    parMainFunnelID = mainFunnel.sfid;
    parUid = mainFunnel.uniqueIdentifier;

    const nextPage = await this.calculateNextPage(parDefaultFunnel, parDefaultFunnel.variants, parMainFunnelID);
    if (!nextPage) return;

    const { url, contentId, variantId } = nextPage;
    this.setContentIdToQuery(contentId);
    const component = await this.getComponent(url);
    if (!component) return;

    this.saveFunnelHistory(parMainFunnelID, parDefaultFunnel, url, variantId);

    let splitTestingId = null;
    mainFunnel.defaultFunnels.forEach((e) => {
      if (e.splitTesting && e.splitTesting.length > 0) {
        splitTestingId = e.splitTesting[0].sfid;
      }
    });

    params = {
      component,
      orderForm,
      type: parType,
      defaultFunnel: parDefaultFunnel,
      mainFunnelID: parMainFunnelID,
      url,
      uid: parUid,
      splitTestingPageId: splitTestingId,
      eventType: 'Page View',
    };
    if (!params) return;

    this.sendStatsToAPI(params);
    if (!this.urls.getParameterFromUrl({ url: this.urls.getQueryParams(), parameter: UNIQUE_IDENTIFIER })) {
      this.setUID(params.uid);
    }
    this.setNextStep(params.defaultFunnel.step);

    return params;
  }
}
