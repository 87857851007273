import { ToastController } from '@ionic/angular';
import { ToastOptions } from '@ionic/core';

import { LoggerProvider } from '../logger/logger';

import { ErrorOptions } from '../../models/errorOptions';
import { LogData } from '../../models/logData';

import { SMTH_WENT_WRONG, SUCCESS, ERROR } from '../../constants/constants';

export class HandlerBase {

  constructor(
    public toastCtrl: ToastController,
    public loggerProvider: LoggerProvider
  ) {}

  // Handles errors
  handleError(options?: ErrorOptions): Promise<void> {
    if (options) {
      // Log error to web browser console and to server
      this.loggerProvider.logToServer({
        level: 'error',
        message: `${ERROR} on ${options.methodName}(): `,
        logObject: { error: options.error }
      } as LogData);

      // Compile toast message for user
      const message = options.errorMessage
        ? options.errorMessage
        : `${ERROR}! ${
          options.error && options.error.message
            ? options.error.message
            : SMTH_WENT_WRONG
        }`;

      return this.showToast({ message, position: 'top', showCloseButton: true, cssClass: 'errorToast' } as ToastOptions);
    } else {
      return Promise.resolve();
    }
  }

  // Shows toast message
  async showToast(options?: ToastOptions): Promise<void> {
    if (options) {
      // Set default message
      if (!options.message) {
        options.message = `${SUCCESS}!`;
      }

      return (await this.toastCtrl
        .create(options))
        .present();
    } else {
      return Promise.resolve();
    }
  }
}
