import { BrowserBase } from './browser-base';

import { Injectable, NgZone } from '@angular/core';

import { FunnelSettingsProvider } from '../funnel-settings/funnel-settings';
import { GeneralProvider } from '../general/general';
import { LoggerProvider } from '../logger/logger';

@Injectable({
  providedIn: 'root',
})
export class BrowserProvider extends BrowserBase {

  constructor(
    public ngZone: NgZone,
    protected _funnelSettings: FunnelSettingsProvider,
    protected _genProvider: GeneralProvider,
    protected _loggerProvider: LoggerProvider
  ) {
    super(
      ngZone,
      _funnelSettings,
      _genProvider,
      _loggerProvider
    );
  }
}
