import { AmazonPayBase } from './amazonPay-base';

import { Injectable, NgZone } from '@angular/core';

import { BrowserProvider } from '../browser/browser';
import { TouchcrApiOrderProvider } from '../touchcr-api-order/touchcr-api-order';
import { SpinnerProvider } from '../spinner/spinner';
import { FunnelSettingsProvider } from '../funnel-settings/funnel-settings';
import { UrlsProvider } from '../urls/urls';
import { CookiesProvider } from '../cookies/cookies';
import { NavControllerExt } from '../navigation-controller-extension/nav-controller-extension';
import { StateProvider } from '../state/state';
import { HandlerProvider } from '../handler/handler';
import { LoggerProvider } from '../logger/logger';

import { FormatHelper } from '../../utils/formatHelper/formatHelper';

@Injectable({
  providedIn: 'root',
})
export class AmazonPay extends AmazonPayBase {

  constructor(
    protected navCtrl: NavControllerExt,
    protected _zone: NgZone,
    protected _tcrApiOrder: TouchcrApiOrderProvider,
    protected _browser: BrowserProvider,
    protected _format: FormatHelper,
    protected _spinner: SpinnerProvider,
    protected _funnelSettings: FunnelSettingsProvider,
    protected _cookies: CookiesProvider,
    protected _urls: UrlsProvider,
    protected _stateProvider: StateProvider,
    protected _hander: HandlerProvider,
    protected _loggerProvider: LoggerProvider
  ) {
    super(
      navCtrl,
      _zone,
      _tcrApiOrder,
      _browser,
      _format,
      _spinner,
      _funnelSettings,
      _cookies,
      _urls,
      _stateProvider,
      _hander,
      _loggerProvider
    );
  }
}
