import { EventStreamerProvider } from '../event-stream/event-stream';

import { Router } from '@angular/router';

import { StateProvider } from '../state/state';
import { FunnelSettingsProvider } from '../funnel-settings/funnel-settings';
import { UrlsProvider } from '../urls/urls';
import { GeneralProvider } from '../general/general';

import { FIRST, LAST, PAGE_NOT_FOUND } from '../../constants/constants';

import { defaultPages } from '../../app-routing.module';
import { LoggingProvider } from '../../services/logging/logging';


export class NavControllerBase {
  constructor(
    public eventStreamer: EventStreamerProvider,
    public router: Router,
    public funnelProvider: FunnelSettingsProvider,
    public stateProvider: StateProvider,
    public urlsProvider: UrlsProvider,
    public generalProvider: GeneralProvider,
    public loggingProvider: LoggingProvider,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
  }

  setRootExt(page: string, params: any, opts: any = {}) {
    const queryParams = Object.assign({}, this.urlsProvider.getQueryStringAsObject(this.urlsProvider.getQueryParams()));
    this.urlsProvider.replaceInitialQueryParams();
    return this.router.navigate([page], { state: params , queryParams })
      .then(() => {
        this.streamEvent(page, params);
      });
  }

  // Navigates to the first page of current funnel
  async navigateToFirstStep(): Promise<any> {
    const params: any = await this.funnelProvider.getPageByStep(FIRST);
    if (!params) {
      this.generalProvider.enableBackButton();
      return this.setRootExt(defaultPages.home.path, {
        name: defaultPages.home.name
      });
    }

    return this.initiateRedirection(params);
  }

  // Navigates to the last page of current funnel
  async navigateToLastStep(): Promise<any> {
    const params: any = await this.funnelProvider.getPageByStep(LAST);
    if (!params) {
      this.generalProvider.enableBackButton();
      return this.setRootExt(defaultPages.ordersuccess.path, {
        name: defaultPages.ordersuccess.name
      });
    }

    return this.initiateRedirection(params);
  }

  async navigateToNextStep(
    genericUrl: string = '',
    button: boolean = false,
    pageType: string = '',
    isPageFail: boolean = false
  ): Promise<any> {
    const params: any = await this.funnelProvider.getNextPage(genericUrl, button, pageType, isPageFail);
    if (!params) {
      this.loggingProvider.logEvent(`Error! Page Not Found`);
      return Promise.reject<any>({ message : PAGE_NOT_FOUND });
    }

    return this.initiateRedirection(params, isPageFail);
  }

  // Method for get router state. Should be used in constructor or ngOnInit
  getNavParams() {
    return (this.router &&
      this.router.getCurrentNavigation() &&
      this.router.getCurrentNavigation().extras &&
      this.router.getCurrentNavigation().extras.state) || {};
  }

  // Redirects to page
  private async initiateRedirection(params: any, isPageFail: boolean = false) {
    const { component, orderForm, type } = params;
    if (!isPageFail) {
      if (type === 'Upsell' || type === 'DownSell') {
        await this.stateProvider.setUpsells([orderForm]);
      }
    }

    let isUpsell = false;
    if (type === 'Upsell' || type === 'DownSell' || type === 'Thankyou Page') {
      isUpsell = true;
    }

    this.generalProvider.enableBackButton();
    return this.setRootExt(component.path, { name: component.name, isUpsell });
  }

  // Streams event to GTM
  private streamEvent(page: string, params: any) {
    this.eventStreamer.streamEvent('appScreenView', {
      screenPath: page,
      screenName: params ? params.name : page,
      customTitle: params ? params.customTitle ? params.customTitle : params.name : page,
      fullUrl: window.location.href,
    });
  }
}
