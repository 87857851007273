import { StateBase } from './state-base';

import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { PreviewProvider } from '../preview/preview';
import { UrlsProvider } from '../urls/urls';

@Injectable({
  providedIn: 'root',
})
export class StateProvider extends StateBase {

  private readonly ACC_DATA: string = 'savedAccount';
  private readonly CHECKBOX_STATUS: string = 'checkBoxStatus';

  constructor(
    public storage: Storage,
    public urlsHelper: UrlsProvider,
    public preview: PreviewProvider
  ) {
    super(
      storage,
      urlsHelper,
      preview
    );
  }

  getCheckboxStatus(): Promise<boolean> {
    return this.storage.get(this.CHECKBOX_STATUS);
  }

  saveCheckboxStatus(status: boolean): Promise<boolean> {
    return this.storage.set(this.CHECKBOX_STATUS, status);
  }

  getSavedAccount(): Promise<any> {
    return this.storage.get(this.ACC_DATA);
  }

  saveAccount(account: any): Promise<any> {
    if (!account) return Promise.resolve();
    return this.storage.set(this.ACC_DATA, account);
  }

  deleteAccount(): Promise<any> {
    return this.storage.set(this.ACC_DATA, null);
  }
}
