import { TouchcrApiAffiliateBase } from './touchcr-api-affiliate-base';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LoggerProvider } from '../logger/logger';

@Injectable({
  providedIn: 'root',
})
export class TouchcrApiAffiliateProvider extends TouchcrApiAffiliateBase {
  constructor(
    public http: HttpClient,
    public loggerProvider: LoggerProvider
  ) {
    super(
      http,
      loggerProvider
    );
  }
}
