/*--- Custom constants --*/
export const PARAM: string = 'n';
export const OBTCR: string = 'obtcr';
export const TBATCR: string = 'tbatcr';
export const FBTCR: string = 'fbtcr';
export const ATBATCR = 'atbatcr';
export const AZEMTCR = 'azemtcr';
export const REVTCR = 'revtcr';
export const YAHTCR = 'yahtcr';
export const TBAMSNTCR = 'tbamsntcr';
export const ADV: string = 'Advertorial';
export const ADVERT: string = 'Advertisement';
export const OPT: string = 'opt';
export const AFFTBA: string = 'afftba';
export const AFFOB: string = 'affob';

export const SHIPPING_INFO: string = 'shipping';
export const BILLING_INFO: string = 'billing';

export const COOKIE_RETURNING_USER = 'returningUser';
export const HIDE_ELEMENT_CLASS: string = 'hide-element';
export const CITATIONS_ID: string = 'citations-list';
export const VSL_CITATIONS_ID: string = 'vslCitations-link';
export const STUDY_ID: string = 'study-paragraph';
export const LEARN_ID: string = 'learn-section';

// to support porting simple video player component from ionic 3 for the apple pay port
export const OOYALA_CLASS = "ooyala-player-Gundry-MD";



export const BRIGHTCOVE_SCRIPTS = [
  "https://players.brightcove.net/6058004204001/DPUqvBFAwn_default/index.min.js"];

export const VIDEO_STYLE_REL: string = 'stylesheet';
export const VIDEO_STYLE_HREF: string = '//player.ooyala.com/static/v4/stable/4.24.9/skin-plugin/html5-skin.min.css';
export const VIDEO_CUSTOM_STYLES: string = '.oo-control-bar {\n\tvisibility: visible !important;\n}' +
  '\n.oo-start-screen-linear-gradient {\n' +
  '\tdisplay: none;\n}';
export const qadev = '.vjs-progress-control, .vjs-control-bar, .vjs-current-time, .vjs-time-divider, .vjs-duration {\n\tdisplay: flex !important;\n}';
export const noTimeMarkerOnVideo: string = '.vjs-time-control{\n\tdisplay: none !important;\n}';
export const showScrubBar: string = '.vjs-progress-control{\n\tdisplay: initial !important;\n}';
export const afftbaParam: string = '.afftbaParam{\n\tdisplay: initial !important;\n}';
export const affobParam: string = '.affobParam{\n\tdisplay: initial !important;\n}';
export const obParam: string = '.obParam{\n\tdisplay: initial !important;\n}';
export const tbaParam: string = '.tbaParam{\n\tdisplay: initial !important;\n}';
export const fbParam: string = '.fbParam{\n\tdisplay: initial !important;\n}';
export const afftbaMobileParam: string = '.afftbaMobileParam{\n\tdisplay: initial !important;\n}';
export const affobMobileParam: string = '.affobMobileParam{\n\tdisplay: initial !important;\n}';
export const obMobileParam: string = '.obMobileParam{\n\tdisplay: initial !important;\n}';
export const tbaMobileParam: string = '.tbaMobileParam{\n\tdisplay: initial !important;\n}';
export const fbMobileParam: string = '.fbMobileParam{\n\tdisplay: initial !important;\n}';
export const QUIZ_WRAPPER: string = 'question__wrapper';
export const LEARN_SECTION: string = 'learn-info{\n\tdisplay: initial !important;\n}';
export const ICONS_SECTION: string = '.iconsSection{\n\tdisplay: initial !important;\n}';
/*--- End Custom constants --*/

// Messages
export const DEFAULT_ADDRESS_ERROR: string = 'Please, enter valid address and phone number!';
export const INVALID_BILLING_ADDRESS_ERROR: string = 'The country or state you selected for billing is out of the range of our service.' +
  ' Please proceed to your payment sheet to update your country in billing section.'
export const INVALID_ADDRESS: string = 'Entered address is not valid';
export const VALIDATION_ERROR: string = 'Sorry, but we couldn\'t validate your address';
export const DO_NOT_SHIP: string = 'Sorry, but we don\'t ship to Your country. Please, select one from the list';
export const NO_SHIPPING_OPTIONS: string = 'Couldn\'t calculate shipping options';
export const TAX_ERROR: string = 'Sorry, but we couldn\'t calculate the tax';
export const PAGE_NOT_FOUND: string = 'Page not found';
export const SMTH_WENT_WRONG: string = 'Something went wrong';
export const PAYMENT_FAILED: string = 'Sorry, but your payment failed';
export const PAYMENT_SERVICE_IS_NOT_AVAILABLE: string = 'Sorry, but Payment Service is not available!';
export const STATE_NOT_FOUND: string = 'Sorry, but the information regarding your order is missing';
export const ORDER_OR_UPSELLS_NOT_FOUND: string = 'Sorry, but we couldn\'t find One time Offer information';
export const FUNNEL_NOT_FOUND: string = 'Sorry, but we couldn\'t find Offer information';
export const COUPON_NOT_FOR_PRODUCT: string = 'Coupon can not be applied to this product';
export const COUPON_NOT_FOR_SUBSCRIPTION: string = 'Coupon can not be applied to subscription';
export const SUCCESS: string = 'Success';
export const ERROR: string = 'Error';
export const REVIEW_AND_SUBMIT_ORDER: string = 'Please review then click "Submit Order" below to place your order';

// Amazon payment scripts
export const AMAZON_BUTTON_ID: string = 'AmazonPayButton';
export const AMAZON_PLACE_ORDER_ID: string = 'placeOrder';
export const ADDRESS_BOOK_WIDGET_ID: string = 'addressBookWidgetDiv';
export const WALLET_WIDGET_ID: string = 'walletWidgetDiv';
export const CONSENT_WIDGET_ID: string = 'consentWidgetDiv';

//Apple Pay, Google pay, Microsoft Pay
export const DEFAULT_CURRENCY = 'usd';

// In-App browsers
export const FACEBOOK: string = 'Facebook';
export const WEBVIEW: string = 'WebView';
export const WEBKIT: string = 'WebKit';

// Wistia player script
// export const VIDEO_SCRIPTS: Array<string> = ['https://fast.wistia.com/assets/external/E-v1.js'];
export const VIDEO_SCRIPTS: Array<string> = [];
export const WISTIA_CLASS: string = 'wistia-player-funnel';
export const VIDEO_CONTAINER: string = '.wistia-player-container';

// Payment buttons IDs
export const UPGRADE_ORDER_ID: string = 'agree-button';
export const NO_THANKS_ID: string = 'no-thanks-link';

// Steps for navigation
export const FIRST: string = 'first';
export const LAST: string = 'last';

// Lead creation delay for checkout forms
export const LEAD_CREATION_DELAY: number = 3000;

// DebugProvider data
export const UPSELL_DATA: any = {
  pageName: 'Upsell',
  date: new Date().getTime(),
  shippingInfo: {
    isValid: false
  },
  taxData: {
    isValid: false
  },
  orderDetails: {
    navParams: {
      upsells: [],
      order: 1,
      paymentMethod: ''
    },
    paymentMethod: 'Standard',
    upsellIndex: 0,
    upsells: [{
      sfid: 'some-value',
      standardPrice: 49.99,
      offerPrice: 19.99,
      shippingCost: 1.99,
      productDescription: 'productDescription',
      description: `<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum inventore praesentium nesciunt odit commodi, officiis odio voluptate repellendus, <b>quia amet voluptatum qui dicta facere neque accusantium assumenda?</b> Vel repellat velit, sunt eveniet itaque inventore aut?</p><p>Laboriosam fuga eveniet iste, placeat voluptates impedit error ex similique sit sunt repellat harum dolor est itaque illo, in hic, repellendus omnis! Sunt, mollitia. Commodi adipisci laborum molestiae sunt veritatis culpa.</p><p><i>Ad repellendus amet consectetur atque dolorem molestiae quo pariatur?</i> Necessitatibus, veniam aliquam. Repudiandae at officia ipsa exercitationem velit provident, illo maiores, enim dignissimos perspiciatis delectus facere natus id blanditiis ratione, unde possimus commodi nesciunt.</p>`,
      product: {
        name: 'Product name',
        image: [{
          imageUrl: 'https://volimpodgoricu.me/wp-content/uploads/2015/06/fotosearch_k4671947.jpg',
        }]
      }
    }],
    order: 1
  }
};
export const ORDER_SUCCESS_DATA: any = {
  pageName: 'OrderSuccess',
  date: new Date().getTime(),
  accountDetails: {
    firstName: 'Test',
    lastName: 'User',
    email: 'test.email@test.com',
    phone: '0000000000',
    country: 'United States',
    state: 'Illinois',
    city: 'Jacksonville',
    street: '602 E Douglas Ave',
    zipPostalCode: '62650',
    apartment: '3',
    additionalAddressLine: '602 E Douglas Ave'
  },
  taxData: {
    isValid: true
  },
  orderDetails: {
    subscriptionFrequencyMultiplier: null,
    subscriptionFrequency: null,
    subscriptionTax: null,
    subscriptionShipping: null,
    subscriptionSubtotal: null,
    subscriptionId: null,
    orderItems: [
      {
        productTaxCode: '464646',
        productDescription: 'New snapback',
        productImage: 'https://fanatics.frgimages.com/FFImage/thumb.aspx?i=/productimages/_2925000/ff_2925857_full.jpg&w=340',
        isBumpOffer: false,
        productVariantSize: null,
        productVariantColor: null,
        productVariantName: null,
        productVariantPrice: null,
        productVariantId: null,
        productName: 'Snap back',
        productPrice: 30,
        productId: 'some-value',
        totalPrice: 30,
        quantity: 1,
        orderId: 'some-value',
        order: 'ORD-000000001',
        name: 'Order-000001',
        id: 'some-value'
      },
      {
        productTaxCode: 'P0000000',
        productDescription: 'Rose Pink Mineral-Based Blush - Paraben-Free, Gluten-Free, Vegan, Cruelty-Free Formula ' +
          'by Cate McNabb Cosmetics',
        productImage: 'https://images-na.ssl-images-amazon.com/images/I/81dy4Jen%2BML._SL1500_.jpg',
        isBumpOffer: false,
        productVariantSize: null,
        productVariantColor: null,
        productVariantName: null,
        productVariantPrice: null,
        productVariantId: null,
        productName: 'Desert Rose',
        productPrice: 0,
        productId: 'some-value',
        totalPrice: 0,
        quantity: 12,
        orderId: 'some-value',
        order: 'ORD-000000001',
        name: 'Order-000002',
        id: 'some-value'
      }
    ],
    orderTransactionFee: 1.98,
    tax: 4.62,
    brand: 'Some Brand',
    shippingZipPostal: '62650',
    additionalBillingAddressLine: null,
    additionalShippingAddressLine: '602 E Douglas Ave',
    billingApartment: null,
    billingAddress: '602 E Douglas Ave',
    billingZip: '62650',
    billingState: 'Illinois',
    billingCity: 'Jacksonville',
    billingCountry: 'United States',
    shippingApartment: '3',
    shippingAddress: '602 E Douglas Ave',
    shippingState: 'Illinois',
    shippingCity: 'Jacksonville',
    shippingCountry: 'United States',
    shipToName: 'Test User',
    orderShippingCost: 14.83,
    orderSource: 'Funnel',
    parrentUpsellOrderId: null,
    orderType: 'Standard',
    latitude: null,
    longitude: null,
    total: 51.43,
    status: null,
    date: 'Sat 21/09/2019 09:37',
    name: 'ORD-000000001',
    id: 'some-value',
    paymentMethod: 'Credit Card',
    customerEmail: 'test.email@test.com',
    customerName: 'Test User',
    accountId: 'some-value',
    affParameters: {
      affId: '',
      offId: '',
      subId1: '',
      subId2: '',
      subId3: '',
      subId4: '',
      subId5: ''
    },
    subscribed: false,
    authorized: false,
    nicn: '41111111',
    transactionFeeAmount: 0.88,
    transactionFeeDocumentId: 'some-value'
  },
  upsells: [
    {
      subscriptionFrequencyMultiplier: null,
      subscriptionFrequency: null,
      subscriptionTax: null,
      subscriptionShipping: null,
      subscriptionSubtotal: null,
      subscriptionId: null,
      orderItems: [
        {
          productTaxCode: null,
          productDescription: 'Cate Cosmetics',
          productImage: 'https://images.catemcnabbcosmetics.com/Images/811578024295/minimascara-400x400_Vegan.jpg',
          isBumpOffer: false,
          productVariantSize: null,
          productVariantColor: null,
          productVariantName: null,
          productVariantPrice: null,
          productVariantId: null,
          productName: 'UPSELL CONCEALER',
          productPrice: 5,
          productId: 'some-value',
          totalPrice: 5,
          quantity: 1,
          orderId: 'some-value',
          order: 'ORD-000000002',
          name: 'Order-000003',
          id: 'some-value'
        }
      ],
      orderTransactionFee: 0.22,
      tax: 0.51,
      brand: 'Some Brand',
      shippingZipPostal: '62650',
      additionalBillingAddressLine: null,
      additionalShippingAddressLine: '602 E Douglas Ave',
      billingApartment: null,
      billingAddress: '602 E Douglas Ave',
      billingZip: '62650',
      billingState: 'Illinois',
      billingCity: 'Jacksonville',
      billingCountry: 'United States',
      shippingApartment: '3',
      shippingAddress: '602 E Douglas Ave',
      shippingState: 'Illinois',
      shippingCity: 'Jacksonville',
      shippingCountry: 'United States',
      shipToName: 'Test User',
      orderShippingCost: 0,
      orderSource: 'Funnel',
      parrentUpsellOrderId: 'some-value',
      orderType: 'Upsell',
      latitude: null,
      longitude: null,
      total: 5.73,
      status: null,
      date: 'Sat 21/09/2019 09:41',
      name: 'ORD-000000002',
      id: 'some-value',
      paymentMethod: 'Credit Card',
      customerEmail: 'test.email@test.com',
      customerName: 'Test User',
      accountId: 'some-value',
      affParameters: {
        affId: '',
        offId: '',
        subId1: '',
        subId2: '',
        subId3: '',
        subId4: '',
        subId5: ''
      }
    },
    {
      subscriptionFrequencyMultiplier: null,
      subscriptionFrequency: null,
      subscriptionTax: null,
      subscriptionShipping: null,
      subscriptionSubtotal: null,
      subscriptionId: null,
      orderItems: [
        {
          productTaxCode: null,
          productDescription: 'Samsung EHS64',
          productImage: 'https://ae01.alicdn.com/kf/HTB1fFSkbXGWBuNjy0Fbq6z4sXXaK.jpg',
          isBumpOffer: false,
          productVariantSize: null,
          productVariantColor: null,
          productVariantName: null,
          productVariantPrice: null,
          productVariantId: null,
          productName: 'Samsung',
          productPrice: 15,
          productId: 'some-value',
          totalPrice: 15,
          quantity: 1,
          orderId: 'some-value',
          order: 'ORD-000000003',
          name: 'Order-000004',
          id: 'some-value'
        }
      ],
      orderTransactionFee: 0.88,
      tax: 2.05,
      brand: 'Some Brand',
      shippingZipPostal: '62650',
      additionalBillingAddressLine: null,
      additionalShippingAddressLine: '602 E Douglas Ave',
      billingApartment: null,
      billingAddress: '602 E Douglas Ave',
      billingZip: '62650',
      billingState: 'Illinois',
      billingCity: 'Jacksonville',
      billingCountry: 'United States',
      shippingApartment: '3',
      shippingAddress: '602 E Douglas Ave',
      shippingState: 'Illinois',
      shippingCity: 'Jacksonville',
      shippingCountry: 'United States',
      shipToName: 'Test User',
      orderShippingCost: 5,
      orderSource: 'Funnel',
      parrentUpsellOrderId: 'some-value',
      orderType: 'Downsell',
      latitude: null,
      longitude: null,
      total: 22.93,
      status: null,
      date: 'Sat 21/09/2019 09:41',
      name: 'ORD-000000003',
      id: 'some-value',
      paymentMethod: 'Credit Card',
      customerEmail: 'test.email@test.com',
      customerName: 'Test User',
      accountId: 'some-value',
      affParameters: {
        affId: '',
        offId: '',
        subId1: '',
        subId2: '',
        subId3: '',
        subId4: '',
        subId5: ''
      }
    }
  ]
};

// Default country
export const DEFAULT_COUNTRY_CODE: string = 'US';

// Countries back up array
export const COUNTRIES_BACKUP: Array<any> = [{
  value: 'US',
  states: {
    WY: 'Wyoming',
    WI: 'Wisconsin',
    WV: 'West Virginia',
    WA: 'Washington',
    VA: 'Virginia',
    VT: 'Vermont',
    UT: 'Utah',
    TX: 'Texas',
    TN: 'Tennessee',
    SD: 'South Dakota',
    SC: 'South Carolina',
    RI: 'Rhode Island',
    PA: 'Pennsylvania',
    OR: 'Oregon',
    OK: 'Oklahoma',
    OH: 'Ohio',
    ND: 'North Dakota',
    NC: 'North Carolina',
    NY: 'New York',
    NM: 'New Mexico',
    NJ: 'New Jersey',
    NH: 'New Hampshire',
    NV: 'Nevada',
    NE: 'Nebraska',
    MT: 'Montana',
    MO: 'Missouri',
    MS: 'Mississippi',
    MN: 'Minnesota',
    MI: 'Michigan',
    MA: 'Massachusetts',
    MD: 'Maryland',
    ME: 'Maine',
    LA: 'Louisiana',
    KY: 'Kentucky',
    KS: 'Kansas',
    IA: 'Iowa',
    IN: 'Indiana',
    IL: 'Illinois',
    ID: 'Idaho',
    HI: 'Hawaii',
    GA: 'Georgia',
    FL: 'Florida',
    DC: 'District of Columbia',
    DE: 'Delaware',
    CT: 'Connecticut',
    CO: 'Colorado',
    CA: 'California',
    AR: 'Arkansas',
    AZ: 'Arizona',
    AK: 'Alaska',
    AL: 'Alabama'
  },
  label: 'United States'
},
  {
    value: 'CA',
    states: {
      YT: 'Yukon Territories',
      SK: 'Saskatchewan',
      QC: 'Quebec',
      PE: 'Prince Edward Island',
      ON: 'Ontario',
      NU: 'Nunavut',
      NS: 'Nova Scotia',
      NT: 'Northwest Territories',
      NL: 'Newfoundland and Labrador',
      NB: 'New Brunswick',
      MB: 'Manitoba',
      BC: 'British Columbia',
      AB: 'Alberta'
    },
    label: 'Canada'
  },
  {
    value: 'GB',
    states: {},
    label: 'United Kingdom'
  },
  {
    value: 'DK',
    states: {},
    label: 'Denmark'
  },
  {
    value: 'NZ',
    states: {},
    label: 'New Zealand'
  },
  {
    value: 'SG',
    states: {},
    label: 'Singapore'
  },
  {
    value: 'VG',
    states: {},
    label: 'Virgin Islands, British'
  },
  {
    value: 'AU',
    states: {
      WA: 'Western Australia',
      VIC: 'Victoria',
      TAS: 'Tasmania',
      SA: 'South Australia',
      QLD: 'Queensland',
      NT: 'Northern Territory',
      NSW: 'New South Wales',
      ACT: 'Australian Capital Territory'
    },
    label: 'Australia'
  },
  {
    value: 'MX',
    states: {
      ZA: 'Zacatecas',
      YU: 'Yucatán',
      VE: 'Veracruz',
      TL: 'Tlaxcala',
      TM: 'Tamaulipas',
      TB: 'Tabasco',
      SO: 'Sonora',
      SI: 'Sinaloa',
      SL: 'San Luis Potosí',
      QR: 'Quintana Roo',
      QE: 'Querétaro',
      PB: 'Puebla',
      OA: 'Oaxaca',
      NL: 'Nuevo León',
      NA: 'Nayarit',
      MO: 'Morelos',
      MI: 'Michoacán',
      ME: 'Mexico State',
      JA: 'Jalisco',
      HG: 'Hidalgo',
      GR: 'Guerrero',
      GT: 'Guanajuato',
      DF: 'Federal District',
      DG: 'Durango',
      CL: 'Colima',
      CO: 'Coahuila',
      CH: 'Chihuahua',
      CS: 'Chiapas',
      CM: 'Campeche',
      BS: 'Baja California Sur',
      BC: 'Baja California',
      AG: 'Aguascalientes'
    },
    label: 'Mexico'
  },
];

export const SINGAPORE = 'Singapore';
export const UNITED_KINGDOM = 'United Kingdom';

export const GOOGLE_PAY_COUNTRIES_MAP = {
  'Zac.': 'ZA',
  'Yuc.': 'YU',
  'Ver.': 'VE',
  'Tlax.': 'TL',
  'Tamps.': 'TM',
  'Tab.': 'TB',
  'Son.': 'SO',
  'Sin.': 'SI',
  'S.L.P.': 'SL',
  'Q.R.': 'QR',
  'Qro.': 'QE',
  'Pue.': 'PB',
  'Oax.': 'OA',
  'N.L.': 'NL',
  'Nay.': 'NA',
  'Mor.': 'MO',
  'Mich.': 'MI',
  'Méx.': 'ME',
  'Jal.': 'JA',
  'Hgo.': 'HG',
  'Gro.': 'GR',
  'Gto.': 'GT',
  'CDMX': 'DF',
  'Dgo.': 'DG',
  'Col.': 'CL',
  'Coah.': 'CO',
  'Chih.': 'CH',
  'Chis.': 'CS',
  'Camp.': 'CM',
  'B.C.S.': 'BS',
  'B.C.': 'BC',
  'Ags.': 'AG'
}

export const SHIPPING_DATA = {
  standart: {
    id: 'Standard USPS',
    label: 'Standard USPS',
    detail: '2-7 Business Days',
  },
  international: {
    id: 'International Shipping',
    label: 'International Shipping',
    detail: '14-28 Business Days',
  },
};
