import { EventStreamerBase } from './event-stream-base';

import { Injectable } from '@angular/core';

// import Keen from 'keen-js';
import { EventParserProvider } from '../event-parse/event-parse';

@Injectable({
  providedIn: 'root',
})
export class EventStreamerProvider extends EventStreamerBase {
  constructor(public dataParser: EventParserProvider) {
    super(dataParser);
  }
}
