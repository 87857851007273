import { UrlsBase } from './urls-base';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UrlsProvider extends UrlsBase {
  constructor() {
    super();
  }
}
