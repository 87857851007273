import { SpinnerBase } from './spinner-base';

import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class SpinnerProvider extends SpinnerBase {
  constructor(public loadingCtrl: LoadingController) {
    super(loadingCtrl);
  }
}
