import { Injectable, NgZone } from '@angular/core';

import { UAParser } from 'ua-parser-js';

import { FunnelSettingsProvider } from '../funnel-settings/funnel-settings';
import { GeneralProvider } from '../general/general';
import { LoggerProvider } from '../logger/logger';

import { LogData } from '../../models/logData';

@Injectable()
export class BrowserBase {

  protected uaParser = new UAParser();
  protected mWindow: any = window;
  // This will override browser support and not restrict browsers
  protected _overrideAmazonPay: boolean = window['process_env'].OVERRIDE_AMAZON_SUPPORT;

  public isMobile: string = /Mobi/.test(navigator.userAgent) ? 'Mobile' : 'Desktop';
  public info: any;

  /**
   * supportedBrowsers
   * array of user agent strings that are supported globally
   */
  protected supportedBrowsers: string[];

  /**
   * supportedAmazonPayBrowsers
   * array of user agent strings that are supported for Amazon Pay Functionality
   */
  protected supportedAmazonPayBrowsers = [
    // windows support
    { OsName: 'Windows', OsMinVersion: 10, BrowserName: 'Chrome', BrowserMinVersion: 78 },
    { OsName: 'Windows', OsMinVersion: 10, BrowserName: 'Firefox', BrowserMinVersion: 70 },
    { OsName: 'Windows', OsMinVersion: 10, BrowserName: 'Internet Explorer', BrowserMinVersion: 11 },
    { OsName: 'Windows', OsMinVersion: 10, BrowserName: 'IE', BrowserMinVersion: 11 },
    { OsName: 'Windows', OsMinVersion: 10, BrowserName: 'Edge', BrowserMinVersion: 18.18362 },

    // mac os support
    { OsName: 'Mac OS', OsMinVersion: 10, BrowserName: 'Chrome', BrowserMinVersion: 78 },
    { OsName: 'Mac OS', OsMinVersion: 10, BrowserName: 'Firefox', BrowserMinVersion: 70 },
    { OsName: 'Mac OS', OsMinVersion: 10, BrowserName: 'Safari', BrowserMinVersion: 13.0 },

    // android support
    { OsName: 'Android', OsMinVersion: 9, BrowserName: 'Chrome', BrowserMinVersion: 78 },

    // iphone support
    // regarding official documentation presented in the link below the supported iOS
    // version is 12+, but tests showed that 11+ works fine
    // see: https://developer.amazon.com/docs/amazon-pay-onetime/browser-support.html
    { OsName: 'iOS', OsMinVersion: 11, BrowserName: 'Mobile Safari', BrowserMinVersion: 11 },

    // in-app browser support
    // instagram
    { OsName: 'Android', OsMinVersion: 9, BrowserName: 'WebView', BrowserMinVersion: 78 },
    // facebook
    { OsName: 'Android', OsMinVersion: 9, BrowserName: 'Facebook', BrowserMinVersion: 266 },
    { OsName: 'iOS', OsMinVersion: 13.1, BrowserName: 'Facebook', BrowserMinVersion: 266 },
    // instagram and facebook
    { OsName: 'iOS', OsMinVersion: 13.1, BrowserName: 'WebKit', BrowserMinVersion: 605 }
  ];

  constructor(
    public ngZone: NgZone,
    protected _funnelSettings: FunnelSettingsProvider,
    protected _genProvider: GeneralProvider,
    protected _loggerProvider: LoggerProvider
  ) {
    this.info = this.uaParser.getResult();
    this.init();
  }

  protected init(): void {}

  /**
   * setOverrideAmazonPaySupport()
   * @param override boolean true will allow all browsers, false will only allow supported browsers
   */
  public setOverrideAmazonPaySupport(override: boolean = false): void {
    this._overrideAmazonPay = override;
  }

  /**
   * getSupportedAmazonBrowsers()
   * @returns Array of supported amazon browsers
   */
  public getSupportedAmazonBrowsers() {
    return this.supportedAmazonPayBrowsers;
  }

  /**
   * isAmazonPaySupported()
   * Checks detected browser and os versions against a list of supported amazon browsers
   * @returns boolean
   */
  public isAmazonPaySupported(): boolean {
    return this._isAmazonPaySupported();
  }

  /**
   * addSupportedAmazonBrowser()
   * Adds the passed browser to the array of browsers for amazon support
   * @param browser obj The brwoser to add
   */
  public addSupportedAmazonBrowser(browser: { OsName: string, OsMinVersion: number, BrowserName: string, BrowserMinVersion: number }) {
    this.supportedAmazonPayBrowsers.push(browser);
    this._isAmazonPaySupported();
  }

  /**
   * getDeviceInfo()
   * @returns formatted device object
   */
  public getDeviceInfo() {
    return this._formattedDeviceInfo();
  }

  protected _sendCustomFunnelEvent(eventType: string, payload: any): void {
    const mergedPayload = Object.assign(payload, this._formattedDeviceInfo());
    this._loggerProvider.logToConsole({
      level: 'log',
      message: '::: TCR Browser Provider Custom Funnel Debug :::',
      logObject: {
        message: ':: Sending a custom funnel event',
        eventType,
        mergedPayload
      }
    } as LogData);
    this._funnelSettings.sendCustomStats(eventType, mergedPayload);
  }

  /**
   * _isAmazonPaySupported()
   * filter against OS, OS Version, Browser and Browser Version to determine if amazon pay is supported.
   * will set window[AmazonPaySupported] to a boolean to indicate the result
   * will check for amazon pay script loaded and fire onAmazonLoginReady if isSupported is true and window[AmazonPaySupported] is false
   * @returns boolean
   */
  protected _isAmazonPaySupported() {
    const logData = {
      level: 'log',
      message: '::: TCR Amazon Support :::'
    } as LogData;

    if (this._overrideAmazonPay) {
      logData['logObject'] = {
        message: 'AmazonPay Support Override is set to true. Browser will not be checked.' +
          ' AmazonPay will be globally allowed on all browsers.'
      };
      this._loggerProvider.logToConsole(logData);
      return true;
    }

    const checkResultArray = [];
    checkResultArray.push({
      detectedDevice: this.info.os.name + ':' + this.info.os.version + ' && '
        + this.info.browser.name + ':' + parseFloat(this.info.browser.version)
    });
    const supportedOs = this.supportedAmazonPayBrowsers.filter((value) => {
      const result = (
          value.OsName.toLowerCase() === this.info.os.name.toLowerCase()
          && parseFloat(this.info.os.version) >= value.OsMinVersion
        )
        && (
          value.BrowserName.toLowerCase() === this.info.browser.name.toLowerCase()
          && parseFloat(this.info.browser.version) >= value.BrowserMinVersion
        );
      checkResultArray.push({
        checking: value.OsName + ':' + value.OsMinVersion + ' && ' + value.BrowserName + ':' + value.BrowserMinVersion,
        supported: result
      });
      return result;
    });
    checkResultArray[0]['supported'] = supportedOs.length > 0;
    logData['logObject'] = checkResultArray;
    this._loggerProvider.logToConsole(logData);
    return supportedOs.length > 0;
  }

  protected _formattedDeviceInfo() {
    return {
      os: `${this.info.os.name} ${this.info.os.version}`,
      browser: `${this.info.browser.name} ${this.info.browser.version}`,
      language: navigator.language || null,
      device: this.isMobile,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };
  }
}
