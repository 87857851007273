import { Storage } from '@ionic/storage';
import { State } from '../../models/state';
import { TaxData } from '../../models/taxData';
import { Coupon } from '../../models/coupon';
import { UrlsProvider } from '../urls/urls';
import { PreviewProvider } from '../preview/preview';

const PREVIEW_MODE = window['process_env'].PREVIEW_MODE;

export class StateBase {
  readonly TWO_STEP_ORDER_FORM: string = 'TwoStepOrderForm';
  readonly CHECKOUT_PERSONAL_INFO: string = 'CheckoutPersonalInfo';
  readonly CHECKOUT_CARD_INFO: string = 'CheckoutCardInfo';
  readonly CHECKOUT: string = 'Checkout';
  readonly UPSELL: string = 'Upsell';
  readonly ORDER_SUCCESS: string = 'OrderSuccess';
  readonly OFFER: string = 'Offer';
  readonly PAYPAL_NVP: string = 'PayPalNVP';
  readonly STATE_PARAM: string = 'state';

  previewMode: string = '';

  constructor(
    public storage: Storage,
    public urlsHelper: UrlsProvider,
    public preview: PreviewProvider
  ) {
    const previewParameter = urlsHelper.getParameterFromUrl({ url: this.urlsHelper.getQueryParams(), parameter: 'preview'});
    this.previewMode = previewParameter ? previewParameter.toLowerCase() : '';
  }

  set(state: State) {
    if (!state) return Promise.resolve();
    state.date = Date.now();
    return this.storage.set(this.STATE_PARAM, state);
  }

  get(): Promise<State> {
    if (PREVIEW_MODE && this.previewMode) {
      if (this.previewMode === this.UPSELL.toLowerCase()) {
        return Promise.resolve(this.preview.getUpsellData());
      } else if (this.previewMode === this.ORDER_SUCCESS.toLowerCase()) {
        return Promise.resolve(this.preview.getOrderSuccessData());
      }
    }

    return this.storage.get(this.STATE_PARAM);
  }

  clean() {
    return this.get().then((state: State) => {
      if (!state) return Promise.resolve();
      delete state.pageName;
      delete state.navParams;
      delete state.orderDetails;
      delete state.funnel;
      delete state.date;
      delete state.accountDetails;
      delete state.taxData;
      delete state.upsells;
      delete state.shippingInfo;
      delete state.coupon;
      delete state.bumpExpireList;
      delete state.selectedProduct;
      delete state.selectedShippingIndex;
      delete state.selectedBumpOffersIds;
      return this.set(state);
    });
  }

  setPageName(pageName: string) {
    return this.get()
      .then((state: State) => {
        if (!state) state = {} as State;
        state.pageName = pageName;
        return this.set(state);
      });
  }

  setPageNameAndOrder(pageName: string, order) {
    return this.get()
      .then((state: State) => {
        if (!state) state = {} as State;
        state.pageName = pageName;
        state.orderDetails = order;
        return this.set(state);
      });
  }

  setNameOrderUpsell(pageName: string, order, upsells) {
    return this.get()
      .then((state: State) => {
        if (!state) state = {} as State;
        state.pageName = pageName;
        state.orderDetails = order;
        state.upsells = upsells;
        return this.set(state);
      });
  }

  /*Methods for purchased upsells*/
  setPurchasedUpsells(upsells) {
    return this.get()
      .then((state: State) => {
        if (!state) state = {} as State;
        if (!state.upsells) state.upsells = [];
        state.upsells.push(upsells);
        return this.set(state);
      });
  }

  clearPurchasedUpsells() {
    return this.get()
      .then((state: State) => {
        if (!state) return Promise.resolve();
        delete state.upsells;
        return this.set(state);
      });
  }
  /*End methods for purchased upsells*/

  setUpsells(upsells) {
    return this.get()
      .then((state: State) => {
        if (!state) state = {} as State;
        if (!state.orderDetails) return Promise.resolve();
        state.orderDetails.upsells = upsells;
        return this.set(state);
      });
  }

  /*Methods for tax*/
  setTaxData(taxData: TaxData) {
    return this.get()
      .then((state: State) => {
        if (!state) state = {} as State;
        if (!taxData.isValid && state.taxData) taxData.isValid = state.taxData.isValid;
        state.taxData = taxData;
        return this.set(state);
      });
  }

  clearTaxDataInfo() {
    return this.get()
      .then((state) => {
        if (!state) return Promise.resolve();
        delete state.taxData;
        return this.set(state);
      });
  }
  /*end methods for tax*/

  /*Methods for order*/
  setOrderDetails(orderDetails) {
    return this.get()
      .then((state: State) => {
        if (!state) state = {} as State;
        state.orderDetails = orderDetails;
        return this.set(state);
      });
  }

  clearOrderDetails() {
    return this.get()
      .then((state) => {
        if (!state) return Promise.resolve();
        delete state.orderDetails;
        return this.set(state);
      });
  }
  /*end methods for order*/

  /*methods for shipping*/
  setShippingInfo(shippingInfo) {
    return this.get()
      .then((state: State) => {
        if (!state) state = {} as State;
        state.shippingInfo = shippingInfo;
        return this.set(state);
      });
  }

  clearShippingInfo() {
    return this.get()
      .then((state) => {
        if (!state) return Promise.resolve();
        delete state.shippingInfo;
        return this.set(state);
      });
  }
  /*end methods for shipping*/

  /*methods for account details*/
  setAccountDetails(accountDetails) {
    return this.get()
      .then((state: State) => {
        if (!state) state = {} as State;
        state.accountDetails = accountDetails;
        return this.set(state);
      });
  }

  clearAccountDetails() {
    return this.get()
      .then((state) => {
        if (!state) return Promise.resolve();
        delete state.accountDetails;
        return this.set(state);
      });
  }
  /*end methods for account details*/

  setSelectedProduct(selectedProduct: string) {
    return this.get()
      .then((state: State) => {
        if (!state) state = {} as State;
        state.selectedProduct = selectedProduct;
        return this.set(state);
      });
  }

  setSelectedShippingIndex(selectedShippingIndex: number) {
    return this.get()
      .then((state: State) => {
        if (!state) state = {} as State;
        state.selectedShippingIndex = selectedShippingIndex;
        return this.set(state);
      });
  }

  setSelectedBumpOffersIds(selectedBumpOffersIds: any) {
    return this.get()
      .then((state: State) => {
        if (!state) state = {} as State;
        state.selectedBumpOffersIds = selectedBumpOffersIds;
        return this.set(state);
      });
  }

  clearSelectedProduct() {
    return this.get()
      .then((state) => {
        if (!state) return Promise.resolve();
        delete state.selectedProduct;
        return this.set(state);
      });
  }

  clearSelectedShippingIndex() {
    return this.get()
      .then((state) => {
        if (!state) return Promise.resolve();
        delete state.selectedShippingIndex;
        return this.set(state);
      });
  }

  clearSelectedBumpOffersIds() {
    return this.get()
      .then((state) => {
        if (!state) return Promise.resolve();
        delete state.selectedBumpOffersIds;
        return this.set(state);
      });
  }

  addBumpExpireItems(bumpoffers) {
    return this.get()
      .then((state: State) => {
        if (!state) state = {} as State;
        const oldList = state.bumpExpireList;
        let bumpArray = bumpoffers.reduce((acc, val) => {
          if (!oldList || !oldList.find((x) => x.sfid === val.sfid)) {
            acc.push({
              sfid: val.sfid,
              viewDate: new Date(),
            });
          }
          return acc;
        }, []);
        bumpArray = oldList ? bumpArray.concat(oldList) : bumpArray;
        state.bumpExpireList = bumpArray;
        this.set(state);
        return bumpArray;
      });
  }

  setCoupon(coupon: Coupon) {
    return this.get()
      .then((state: State) => {
        if (!state) state = {} as State;
        state.coupon = coupon;
        return this.set(state);
      });
  }

  clearCoupon() {
    return this.get()
      .then((state) => {
        if (!state) return Promise.resolve();
        delete state.coupon;
        return this.set(state);
      });
  }


  clearFunnel() {
    return this.get()
      .then(state => {
        if (!state) return Promise.resolve();
        delete state.funnel;
        return this.set(state);
      });
  }
}
